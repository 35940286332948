import React from 'react';

import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { IconButton } from '@material-ui/core';

interface IStockListAlert {
  stockAlerts: any;
  openOrCloseAlertDialog: any;
  canUserAlert: boolean;
  item: any;
  stockListSettings: any;
}

const StockListAlert = ({
  stockAlerts,
  item,
  canUserAlert,
  openOrCloseAlertDialog,
  stockListSettings,
}: IStockListAlert) => {
  return (
    <>
      {stockAlerts[item.itemNumberWarehouse] ? (
        <IconButton
          disabled={!canUserAlert}
          data-id="enableAlert"
          onClick={(e) =>
            openOrCloseAlertDialog(
              true,
              {
                alertQuantity: stockAlerts[item.itemNumberWarehouse].quantity,
                description: stockAlerts[item.itemNumberWarehouse].description,
                availableQuantity: item.quantityAvailable,
                itemNumberWarehouse: item.itemNumberWarehouse,
                incrementSize: item.incrementSize,
              },
              false
            )
          }
        >
          <NotificationsIcon className="vertical-middle" />
        </IconButton>
      ) : (
        <IconButton
          disabled={!canUserAlert}
          data-id="disableAlert"
          onClick={(e) => {
            let alertQuantity = stockListSettings['defaultAlertQuantity'] || 100;
            alertQuantity =
              alertQuantity <= item.incrementSize
                ? item.incrementSize
                : Math.floor(alertQuantity / item.incrementSize) * item.incrementSize;

            openOrCloseAlertDialog(
              false,
              {
                alertQuantity: alertQuantity,
                description: item.skuDescription,
                availableQuantity: item.quantityAvailable,
                itemNumberWarehouse: item.itemNumberWarehouse,
                incrementSize: item.incrementSize,
              },
              false
            );
          }}
        >
          <NotificationsNoneIcon className="inline-block vertical-middle" />
        </IconButton>
      )}
    </>
  );
};

export default StockListAlert;
