import React, { useContext, useRef } from 'react';
import { FormControlLabel } from '@material-ui/core';

import Dialog from '../Dialog/Dialog';
import PreviewDescription from './PreviewDescription';
import { PrimaryButton, PrimaryCheckbox, TertiaryButton } from '../AtomComponents';

import { I18nContext } from '../../I18n';

import { ParticipantWarehouse } from '../../types/StockListTypes';

interface IWarehouseGroupDescriptionDialog {
  closeDialog: () => void;
  participantId: string;
  pxnWarehousesObj: Record<string, ParticipantWarehouse>;
  acknowledgeDialog: (participantId: any) => Promise<boolean>;
  type: 'info' | 'prompt';
  promptSuccessCb?: any;
}

function WarehouseGroupDescriptionDialog({
  closeDialog,
  participantId,
  pxnWarehousesObj,
  acknowledgeDialog,
  promptSuccessCb,
  type,
}: IWarehouseGroupDescriptionDialog) {
  const I18n = useContext(I18nContext);
  const doNotShowAgainRef = useRef<any>(null);

  const description =
    pxnWarehousesObj && participantId in pxnWarehousesObj
      ? pxnWarehousesObj?.[participantId]?.warehouseGroupDescription
      : '';

  const onContinue = async () => {
    const doNotShowAgain = doNotShowAgainRef.current?.checked;
    if (doNotShowAgain) {
      const isAcknowledged = await acknowledgeDialog(participantId);
      // failed to acknowledge
      if (!isAcknowledged) return;
    }
    closeDialog();
    if (promptSuccessCb) promptSuccessCb();
  };

  let ACTION = (
    <>
      <FormControlLabel
        data-id="doNotShowAgain"
        label={I18n.doNotShowAgain?.i18n_value || 'Do Not Show Again'}
        inputRef={doNotShowAgainRef}
        control={<PrimaryCheckbox />}
      />
      <PrimaryButton data-id="continue" onClick={onContinue}>
        {I18n.continue?.i18n_value || 'CONTINUE'}
      </PrimaryButton>
    </>
  );

  if (type === 'info') {
    ACTION = (
      <>
        <span></span>
        <TertiaryButton data-id="close" onClick={closeDialog}>
          {I18n.close?.i18n_value || 'CLOSE'}
        </TertiaryButton>
      </>
    );
  }

  return (
    <Dialog
      isDialogOpen={true}
      title={pxnWarehousesObj?.[participantId]?.warehouseGroupName || ''}
      closeDialog={closeDialog}
      className="warehouse-group-description-dialog"
      content={
        <PreviewDescription
          description={description}
          dataId={`warehouseGroupDescription-${participantId}`}
        />
      }
      actions={<div className="cell small-12 grid-x align-justify">{ACTION}</div>}
    />
  );
}

export default WarehouseGroupDescriptionDialog;
