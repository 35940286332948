import React, { useMemo, useState, useRef, useEffect } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './ReviewStockImport.scss';
import { makeStyles } from '@material-ui/core/styles';
import InValidRequestBox from './InvalidRequestBox/InvalidRequest';
import ValidRequestBox from './ValidRequestBox/ValidRequestBox';
import OverwriteRequestBox from './OverwriteRequestBox/OverwriteRequestBox';
import { Typography } from '@material-ui/core';
import useDataService from '../../hooks/useDataService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as stockActions from '../../redux/actions/stockGroupActions';
import { Link } from 'react-router-dom';
import { I18nContext } from '../../I18n';
import { PrimaryButton, TertiaryButton } from '../AtomComponents';
import { eMessageType } from '../../types/IMessageType';

export const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '',
    },
  },
}));

function ReviewStockImport(props: any) {
  const I18n = React.useContext(I18nContext);

  const classes = useStyles();

  const {
    setOpenDifferentView,
    data,
    stockListSettings,
    warehousesObj,
    curTenantSettings,
    stockActions,
    setLoading,
  } = props;

  const warehouseGroupAcceptsOffers = props.isPxns
    ? !!props.stockListSettings?.warehouseAcceptingOffer?.length
    : !!props?.stockListSettings?.acceptOffer;

  const [overwriteItems, setOverwriteItems] = useState(data['conflictOffers']);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const invalidRequestRef = useRef<HTMLDivElement | null>(null);
  const overwriteRequestRef = useRef<HTMLDivElement | null>(null);
  const validRequestRef = useRef<HTMLDivElement | null>(null);
  const invalidRequestLinkRef = useRef<HTMLDivElement | null>(null);
  const overwriteRequestLinkRef = useRef<HTMLDivElement | null>(null);
  const validRequestLinkRef = useRef<HTMLDivElement | null>(null);

  const getDimensions = (ele: any) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  const navigationLinkToRequestMap: any = {
    'invalid-request': invalidRequestLinkRef,
    'overwrite-request': overwriteRequestLinkRef,
    'valid-request': validRequestLinkRef,
  };

  useEffect(() => {
    const cachedRef = headerRef.current,
      observer = new IntersectionObserver(
        ([e]) => {
          e.target.classList.toggle('box-shadow-3', e.intersectionRatio < 1);
        },
        {
          threshold: [1],
        }
      );

    observer.observe(cachedRef!);

    // unmount
    return function () {
      observer.unobserve(cachedRef!);
    };
  }, []);

  // const throttle = (fn: any, delay: any) => {
  //   let last = 0;
  //   return (...args: any) => {
  //     const now = new Date().getTime();
  //     if (now - last < delay) {
  //       return;
  //     }
  //     last = now;
  //     return fn(...args);
  //   };
  // };

  useEffect(() => {
    const { height: headerHeight } = getDimensions(headerRef.current);

    const handleScroll = () => {
      const scrollPosition = window.scrollY + headerHeight + 15;
      [
        { section: 'invalid-request', ref: invalidRequestRef },
        { section: 'overwrite-request', ref: overwriteRequestRef },
        { section: 'valid-request', ref: validRequestRef },
      ].forEach((obj: any) => {
        if (!obj.ref || !obj.ref.current) return;

        const ele = obj.ref.current;
        const { offsetBottom, offsetTop } = getDimensions(ele);
        navigationLinkToRequestMap[obj.section].current.classList.toggle(
          'active',
          scrollPosition >= offsetTop && scrollPosition < offsetBottom
        );
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fetchUrl, openSnackBar } = useDataService();

  const cancelImport = async () => {
    try {
      const response = await fetchUrl(
        'POST',
        '/px-api-gateway/stocklist/offer/bulk-offer-import/cancel',
        {
          body: {},
        }
      );

      if (response) {
        setOpenDifferentView(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hash = (e: any, ref: any, linkref: any) => {
    const el: any = ref.current;
    if (el) {
      window.scrollTo({ top: el.offsetTop - 76, left: 0 });
      if (linkref.current) linkref.current!.classList.toggle('active', true);
    }
  };

  const submitRequests = async (e: any) => {
    try {
      setLoading(true);
      const response = await fetchUrl(
        'POST',
        '/px-api-gateway/stocklist/offer/bulk-offer-import/accept',
        {
          body: {},
        }
      );

      if (response) {
        const offers = await fetchUrl('GET', '/px-api-gateway/stocklist/offers', {});
        stockActions.stockOffersSet(offers);
        setLoading(false);
        setOpenDifferentView(null);
        openSnackBar(
          I18n?.successSubmitOffers?.i18n_value || 'Items successfully added to cart.',
          eMessageType.success
        );
      } else setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } 
  };

  const validRequestsLength: any = useMemo(() => {
    if (data['validOffersMap'] && Object.keys(data['validOffersMap']).length > 0) {
      return Object.keys(data['validOffersMap']).reduce(
        (accumulator: any, currentValue: any) =>
          accumulator + data['validOffersMap'][currentValue].length,
        0
      );
    } else return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isZeroRequests =
    data['invalidOffers'].length === 0 && overwriteItems.length === 0 && validRequestsLength === 0;

  const isValidRequest = validRequestsLength > 0 || overwriteItems.length > 0;

  return (
    <>
      <div id="px-review-import" className="grid-x px-offset-bottom-mobile-view">
        <div className="cell medium-7" ref={headerRef}>
          <h2>{I18n?.reviewImport?.i18n_value || 'Review Import'}</h2>
        </div>
        <Typography variant="h6" className="cell small-12 margin-bottom-1">
          {`${
            isZeroRequests
              ? I18n?.noReqFoundImport?.i18n_value ||
                `No new requests were found in the import file`
              : I18n?.reqsFoundImport?.i18n_value || 'Requests found in import :'
          }`}
        </Typography>
        <div className={`cell small-12 go-to-top px-sticky-header`} ref={headerRef}>
          <div className="grid-x padding-top-1 padding-bottom-1">
            <div className="cell small-8">
              {data['invalidOffers'].length > 0 && (
                <span
                  style={{ height: '100%' }}
                  className={`inline-block margin-right-2 px-navigation-links`}
                  ref={invalidRequestLinkRef}
                >
                  <div style={{ height: '100%' }} className="grid-x align-middle">
                    <WarningIcon color="error" className="margin-right-1" />
                    <Link
                      to="#invalid-request"
                      onClick={(e) => hash(e, invalidRequestRef, invalidRequestLinkRef)}
                      className="uppercase"
                    >
                      <span>{I18n?.invalid?.i18n_value || 'INVALID'}</span>
                      <span>{`: ${data['invalidOffers'].length}`}</span>
                    </Link>
                  </div>
                </span>
              )}
              {overwriteItems.length > 0 && (
                <span
                  style={{ height: '100%' }}
                  className={`inline-block margin-right-2 px-navigation-links`}
                  ref={overwriteRequestLinkRef}
                >
                  <div style={{ height: '100%' }} className="grid-x align-middle">
                    <InfoIcon className={`${classes.warning} margin-right-1`} color="error" />
                    <Link
                      to="#overwrite-request"
                      onClick={(e) => hash(e, overwriteRequestRef, overwriteRequestLinkRef)}
                      className="uppercase"
                    >
                      <span>{I18n?.update?.i18n_value_plural || 'UPDATES'}</span>
                      <span>{`: ${overwriteItems.length}`}</span>
                    </Link>
                  </div>
                </span>
              )}
              {validRequestsLength > 0 && (
                <span
                  style={{ height: '100%' }}
                  className={`inline-block margin-right-1 px-navigation-links`}
                  ref={validRequestLinkRef}
                >
                  <div style={{ height: '100%' }} className="grid-x align-middle">
                    <CheckCircleIcon
                      className={`${classes.success} margin-right-1`}
                      color="error"
                    />
                    <Link
                      to="#valid-request"
                      onClick={(e) => hash(e, validRequestRef, validRequestLinkRef)}
                    >
                      <span>{I18n?.new?.i18n_value || 'NEW'}</span>
                      <span>{`: ${validRequestsLength}`}</span>
                    </Link>
                  </div>
                </span>
              )}
            </div>
            <div className="cell auto">
              <div className="grid-x align-middle align-right" style={{ height: '100%' }}>
                <TertiaryButton className="margin-right-1" onClick={cancelImport}>
                  {I18n?.cancelImport?.i18n_value || 'CANCEL IMPORT'}
                </TertiaryButton>
                <PrimaryButton
                  onClick={submitRequests}
                  disabled={isZeroRequests || !isValidRequest}
                >
                  {I18n?.submitReq?.i18n_value_plural || 'SUBMIT REQUESTS'}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        {data['invalidOffers'] && data['invalidOffers'].length > 0 && (
          <InValidRequestBox
            data={data['invalidOffers']}
            warehousesObj={warehousesObj}
            stockListSettings={stockListSettings}
            curTenantSettings={curTenantSettings}
            ref={invalidRequestRef}
            warehouseGroupAcceptsOffers={warehouseGroupAcceptsOffers}
          />
        )}
        {overwriteItems && overwriteItems.length > 0 && (
          <OverwriteRequestBox
            data={overwriteItems}
            warehousesObj={warehousesObj}
            stockListSettings={stockListSettings}
            curTenantSettings={curTenantSettings}
            setOverwriteItems={setOverwriteItems}
            ref={overwriteRequestRef}
            warehouseGroupAcceptsOffers={warehouseGroupAcceptsOffers}
          />
        )}
        {validRequestsLength > 0 && (
          <ValidRequestBox
            data={data['validOffersMap']}
            warehousesObj={warehousesObj}
            stockListSettings={stockListSettings}
            curTenantSettings={curTenantSettings}
            totalValidRequestsLength={validRequestsLength}
            ref={validRequestRef}
            warehouseGroupAcceptsOffers={warehouseGroupAcceptsOffers}
          />
        )}
      </div>
    </>
  );
}

function mapDispatchToProps(dispatch: any) {
  return {
    stockActions: bindActionCreators(stockActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ReviewStockImport);
