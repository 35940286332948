import { checkNullOrUndefined } from '../../_lib/lib';
import {
  gradeFilterGroups,
  KeysOfStockListSettings,
  StockListSettings,
} from '../../types/StockListTypes';

export const getKeyTitle = (settings: StockListSettings, subCat: string, key: string) => {
  return settings[`${subCat}Labels` as KeysOfStockListSettings] &&
    settings[`${subCat}Labels` as KeysOfStockListSettings][key]
    ? settings[`${subCat}Labels` as KeysOfStockListSettings][key]
    : key;
};

type gradeGroupResponse = Array<gradeFilterGroups>;
export const generateGradeGroup = (settings: StockListSettings): gradeGroupResponse => {
  if (!settings.gradeFilterGroups) return [{ header: '', footer: '', grades: settings.grade }];
  return settings.gradeFilterGroups.map((group) => {
    return {
      header: group.header,
      footer: group.footer,
      grades: group.grades,
    };
  });
};

const getElisionText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  const sectionLength = Math.max(maxLength / 3, 3),
    thirtyThreePercent = text.length / 3;
  const fistSectionEnd = sectionLength,
    secondSectionStart = thirtyThreePercent,
    thirdSectionStart = text.length - sectionLength;
  const firstSection = text.slice(0, fistSectionEnd),
    secondSection = text.slice(secondSectionStart, secondSectionStart + sectionLength),
    thirdSection = text.slice(thirdSectionStart, text.length);
  return `${firstSection}...${secondSection}...${thirdSection}`;
};

export const getGroupWarehouseLevel = (
  groupAttributes: Record<string, any>,
  stockListSettings: any,
  selectedCategory: string,
  warehouseLabel: string,
  hasMoreThanOneWarehouse: boolean,
  isPxnr: boolean
) => {
  if (!hasMoreThanOneWarehouse) return { fullWarehouseLabel: '', trimmedWarehouseLabel: '' };
  let label = '';
  if (
    isPxnr &&
    !!groupAttributes[stockListSettings['groupBottomRightAttribute'][selectedCategory]]
  ) {
    label = warehouseLabel;
  } else if (
    stockListSettings[
      `${stockListSettings['groupBottomRightAttribute'][selectedCategory]}Labels`
    ] &&
    stockListSettings[`${stockListSettings['groupBottomRightAttribute'][selectedCategory]}Labels`][
      groupAttributes[stockListSettings['groupBottomRightAttribute'][selectedCategory]]
    ]
  ) {
    label =
      stockListSettings[
        `${stockListSettings['groupBottomRightAttribute'][selectedCategory]}Labels`
      ][groupAttributes[stockListSettings['groupBottomRightAttribute'][selectedCategory]]];
  } else {
    label = groupAttributes[stockListSettings['groupBottomRightAttribute'][selectedCategory]];
  }
  let trimmedLabel = label;
  if (label && label.length > 26) {
    trimmedLabel = getElisionText(label, 26);
  }
  return {
    fullWarehouseLabel: label,
    trimmedWarehouseLabel: trimmedLabel,
  };
};

export const getExpandCollapseStockGroupLabel = (
  I18n: Record<string, any>,
  isOfferAccepted: boolean,
  expandState: boolean
) => {
  if (expandState) return '';
  if (!isOfferAccepted) return I18n.buy?.i18n_value || 'Buy';
  return I18n.buyOrMakeOffer?.i18n_value || 'Buy or Make Offer';
};

export const checkAllItemsHasSpecialPrice = (items: any) => {
  return items.every((item: any) => !!item.specialPrice);
};

export const showSalesOptions = (categorySaleOption: any, item: any) => {
  const saleOption = categorySaleOption?.[item.category];
  if (!saleOption || saleOption === 'BOTH') {
    return {
      buy: true,
      offer: true,
    };
  }

  return {
    buy: saleOption === 'BUY',
    offer: saleOption === 'OFFER',
  };
};

export const hideStockListGroupPrices = (settings: any, price?: any) => {
  return settings.hideListPriceForOfferSale && checkNullOrUndefined(price);
};

export const hideStockListItemPrices = (settings: any, category: string) => {
  return settings.hideListPriceForOfferSale && settings.categorySaleOption?.[category] === 'OFFER';
};

export const specialPriceCondition = (specialPrice: number | undefined, listPrice: number) => {
  return specialPrice && specialPrice !== Number.MAX_VALUE && specialPrice < listPrice;
}