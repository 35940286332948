import React, { useContext } from 'react';
import { I18nContext } from '../../../I18n';
import { Package2Icon } from '../../../_lib/icons';
import { Tooltip } from '@material-ui/core';

interface IStocklotIcon {
  item: any;
  type: 'LONG' | 'SHORT';
}

const StocklotIcon = ({ item, type }: IStocklotIcon) => {
  const I18n = useContext(I18nContext);
  if (!item?.lotItemInfoURL) return <></>;
  return (
    <a
      href={item.lotItemInfoURL}
      target="_blank"
      rel="noopener noreferrer"
      className={
        'px-stock-lot-item-info ' +
        (!!item.lotItemInfoURL && type === 'SHORT' && 'padding-0 px-stock-lot-inline-btn')
      }
    >
      <Tooltip
        title={I18n.itemInfo?.i18n_value || 'ITEM INFO'}
        TransitionProps={{ timeout: 0 }}
        classes={{
          tooltip: 'px-stock-lot-item-info',
        }}
        interactive
      >
        <span>
          <Package2Icon />
          {type === 'LONG' && <span>{I18n.itemInfo?.i18n_value || 'ITEM INFO'}</span>}
        </span>
      </Tooltip>
    </a>
  );
};
export default StocklotIcon;
