import React from 'react';
import ShadowDom from '../AtomComponents/ShadowDom';

interface IPreviewDescriptionProps {
  description: string;
  className?: string;
  descriptionClass?: string;
  dataId: string;
}

const PreviewDescription = ({
  description,
  className,
  descriptionClass,
  dataId,
}: IPreviewDescriptionProps) => {
  return (
    <div data-id={dataId} className={`cell small-12 ${className ? className : ''}`}>
      <ShadowDom htmlString={description} className={descriptionClass} />
    </div>
  );
};

export default PreviewDescription;
