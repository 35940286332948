import { Auth } from 'aws-amplify';
const getAwsconfig = (config: any) => {
  const awsConfig = {
    Auth: {
      // REQUIRED - Amazon Cognito Regionmanufacturer,model,capacity
      region: config.region,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: config.userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: config.userPoolWebClientId,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      //this will be a server side lookup or client side look up based on the phonex subdomain or the tenant domain in which
      // this website is currently loaded
      //hardcoding to hyla for now
      clientMetadata: {
        role: 'buyer',
      },
    },
    API: {
      endpoints: [] as {
        name: string;
        endpoint: string;
        custom_header: () => Promise<Record<string, any>>;
      }[],
    },
  };
  const endpointsObj: Record<
    string,
    {
      name: string;
      endpoint: string;
      custom_header: () => Promise<Record<string, any>>;
    }
  > = {};
  let modules = config.modules;
  if (typeof modules === 'string') modules = JSON.parse(modules);
  const tenantApiConfig = {
    tenantApiName: modules?.phonex_saas_mfe_tenant?.tenantApiName,
    tenantApiEndPoint: modules?.phonex_saas_mfe_tenant?.tenantApiEndPoint,
  };
  if (!tenantApiConfig.tenantApiName) tenantApiConfig.tenantApiName = config.tenantApiName;
  if (!tenantApiConfig.tenantApiEndPoint)
    tenantApiConfig.tenantApiEndPoint = config.tenantApiEndPoint;

  const auctionApiConfig = {
    tenantApiName: modules?.phonex_saas_mfe_auction?.tenantApiName,
    tenantApiEndPoint: modules?.phonex_saas_mfe_auction?.tenantApiEndPoint,
  };
  if (!auctionApiConfig.tenantApiName) auctionApiConfig.tenantApiName = config.tenantAuctionApiName;
  if (!auctionApiConfig.tenantApiEndPoint)
    auctionApiConfig.tenantApiEndPoint = config.tenantAuctionApiEndPoint;

  const poApiConfig = {
    tenantApiName: modules?.phonex_saas_mfe_po?.tenantApiName,
    tenantApiEndPoint: modules?.phonex_saas_mfe_po?.tenantApiEndPoint,
  };

  endpointsObj['phonex_saas_mfe_tenant'] = {
    name: tenantApiConfig.tenantApiName,
    endpoint: tenantApiConfig.tenantApiEndPoint,
    custom_header: async () => {
      return {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      };
    },
  };
  endpointsObj['phonex_saas_mfe_auction'] = {
    name: auctionApiConfig.tenantApiName,
    endpoint: auctionApiConfig.tenantApiEndPoint,
    custom_header: async () => {
      return {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      };
    },
  };
  endpointsObj['phonex_saas_mfe_po'] = {
    name: poApiConfig.tenantApiName,
    endpoint: poApiConfig.tenantApiEndPoint,
    custom_header: async () => {
      return {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      };
    },
  };
  awsConfig.API.endpoints = Object.values(endpointsObj);
  return awsConfig;
};
export default getAwsconfig;
