import React, { useContext } from 'react';
import Dialog from '../Dialog/Dialog';
import { PrimaryButton, TertiaryButton } from '../AtomComponents';
import { useHistory } from 'react-router-dom';
import { I18nContext } from '../../I18n';

interface IAutoCheckoutPromptDialog {
  isDialogOpen: boolean;
  closeDialog: () => void;
  acknowledgeAutoCheckoutPromptDialog: () => void;
}

function AutoCheckoutPromptDialog({
  isDialogOpen,
  closeDialog,
  acknowledgeAutoCheckoutPromptDialog,
}: IAutoCheckoutPromptDialog) {
  const I18n = useContext(I18nContext);
  const history = useHistory();

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title={I18n?.autoCheckoutPromptTitle?.i18n_value || 'Auto-Checkout for Accepted Offers'}
      content={
        <div>
          <p>
            {I18n?.autoCheckoutPromptInstruction?.i18n_value ||
              `Enable Auto-Checkout to automatically place orders for you when your offers are
            accepted. This applies to eligible warehouse groups.`}
          </p>
          <p>
            {I18n?.autoCheckoutPromptConfirmationText?.i18n_value ||
              'Would you like to update your Checkout Preferences now?'}
          </p>
        </div>
      }
      actions={
        <div className="grid-x px-autocheckout-prompt-actions">
          <TertiaryButton onClick={closeDialog}>MAYBE LATER</TertiaryButton>
          <div className="grid-y margin-left-2">
            <PrimaryButton onClick={() => history.push('/preferences')}>
              YES, GO TO PREFERENCES
            </PrimaryButton>
            <TertiaryButton className="margin-top-2" onClick={acknowledgeAutoCheckoutPromptDialog}>
              NO, AND DON'T REMIND ME
            </TertiaryButton>
          </div>
        </div>
      }
    />
  );
}

export default AutoCheckoutPromptDialog;
