import apiToUrlMap from '../../../ApiMapping';

export const isItemStockLot = (category: string) => category === 'STOCKLOT';

export const getGroupItemDescription = (item: any, isStockLotGroup?: boolean) => {
  return !!isStockLotGroup ? item.lotShortDescription : item.groupItemDescription;
};

export const isGroupStockLot = (group: any) => {
  return (
    group.items?.length === 1 &&
    group.items.reduce((acc: boolean, item: any) => {
      return acc || isItemStockLot(item.category);
    }, false)
  );
};

export const fetchCartItemLotURL = async (res: Record<string, any>, participantData: any) => {
  const offers = Object.keys(res);
  for (let i = 0; i < offers.length; i++) {
    if (isItemStockLot(res[offers[i]]?.category)) {
    }
  }
};

export const fetchStockLotsToCache = async (fetchUrl: any) => {
  const stockGroups = await fetchUrl('get', apiToUrlMap.stocksGet, {});
  const stockLots: any = {};
  Object.keys(stockGroups.groups).forEach((key: string) => {
    stockGroups.groups[key].items.forEach((item: any) => {
      stockLots[item.itemNumberWarehouse] = {
        lotLongDescription: item.lotLongDescription,
        lotItemInfoURL: item.lotItemInfoURL,
        lotShortDescription: item.lotShortDescription,
        group: stockGroups.groups[key].groupId,
        warehouse: stockGroups.groups[key].warehouse,
      };
    });
  });
  return stockLots;
};
