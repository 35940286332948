import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { ButtonProps, Snackbar, SnackbarProps } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { PrimaryButton } from '../AtomComponents';
import '../../views/Orders/Orders.scss';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export interface ISnackBarWithActionPropsRef {
  open: (message: string, actionProps: IActionButtonProps) => void;
  close: () => void;
}

interface IActionButtonProps extends ButtonProps {
  label?: React.ReactNode;
  handler?: () => void;
}

interface ISnackBarWithActionProps {
  className: string;
  startAdornmentIcon: React.ReactNode;
  snackBarProps?: SnackbarProps & {
    "data-id"?: string;
  };
}

export const SnackBarWithActionProps = forwardRef(
  ({ className, startAdornmentIcon, snackBarProps }: ISnackBarWithActionProps, ref: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [actionButtonProps, setActionButtonProps] = useState<IActionButtonProps>();
    const { label, handler, ...restProps } = actionButtonProps ?? { label: '', handler: () => {} };

    const handleOpen = (message: string, actionProps: IActionButtonProps) => {
      setIsOpen(true);
      setMessage(message);
      setActionButtonProps(actionProps);
    };
    const handleClose = () => {
      setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <Snackbar
        className={`po-snackBar-alert ${className}`}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpen}
        autoHideDuration={300000}
        onClose={handleClose}
        {...snackBarProps}
      >
        <Alert onClose={handleClose} icon={false} style={{ display: 'flex', flexDirection: 'row' }}>
          {startAdornmentIcon}
          <span>{message}</span>
          {label && (
            <PrimaryButton
              data-id="snackBarButton"
              size="small"
              className="snack-button"
              onClick={handler}
              {...restProps}
            >
              {label}
            </PrimaryButton>
          )}
        </Alert>
      </Snackbar>
    );
  }
);
