import React from 'react';
import { dataFormatting } from '../../_lib/lib';
import { Star } from '@material-ui/icons';
import './StockListItem.scss';
import { specialPriceCondition } from './StockListUtils';

interface IStockPriceProps {
  listPrice: number;
  specialPrice?: number;
  showMinPrice?: boolean;
  showMinPriceSymbol?: string;
  variant?: string;
  allItemsHasListPrice?: boolean;
}

const StockPrice = ({
  listPrice,
  specialPrice,
  showMinPrice,
  showMinPriceSymbol,
  variant,
  allItemsHasListPrice,
}: IStockPriceProps) => {
  const stockListPriceWithMinPrice = () => {
    // listPrice is minPrice
    return (
      <span>
        {`${
          listPrice === Number.MAX_VALUE
            ? '--'
            : `${getDisplayPrice()}${showMinPriceSymbol ? '+' : ''}`
        }`}
      </span>
    );
  };

  const stockListPriceWithoutMinPrice = () => {
    return <span> {`${listPrice ? getDisplayPrice() : '--'}`}</span>;
  };

  const getDisplayPrice = () => {
    if(specialPrice && specialPrice !== Number.MAX_VALUE && specialPrice >= listPrice) {
      return `${dataFormatting('currency-with-no-decimal', specialPrice)}`;
    }
    return `${dataFormatting('currency-with-no-decimal', listPrice)}`;
  }

  const stockListPriceWithSpecialPrice = () => {
    const price = showMinPrice ? stockListPriceWithMinPrice() : stockListPriceWithoutMinPrice();
    if (variant === 'GROUP') {
      return (
        <span>
          {specialPriceCondition(specialPrice, listPrice) ? (
            <span className="px-stock-price">
              <span className="px-crossed-list-price">
                <s className="text-faded">{price}</s>
                <Star />
              </span>
              <span className="px-special-Price">{`${`${dataFormatting(
                'currency-with-no-decimal',
                specialPrice
              )}${showMinPriceSymbol ? '+' : ''}`}`}</span>
            </span>
          ) : (
            price
          )}
        </span>
      );
    }
    return (
      <span>
        {specialPriceCondition(specialPrice, listPrice) ? (
          <span className="px-stock-price">
            <span className="px-crossed-list-price">
              <s className="text-faded">{price}</s>
              <Star />
            </span>
            <span className="px-special-Price">{`${`${dataFormatting(
              'currency-with-no-decimal',
              specialPrice
            )}${showMinPriceSymbol ? '+' : ''}`}`}</span>
          </span>
        ) : (
          price
        )}
      </span>
    );
  };

  return stockListPriceWithSpecialPrice();
};

export default StockPrice;
