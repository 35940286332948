import { Checkbox, FormControlLabel, IconButton, RadioGroup } from '@material-ui/core';
import React, { useMemo } from 'react';
import { I18nContext } from '../../I18n';
import { FilterInUserType, ParticipantWarehouse } from '../../types/StockListTypes';
import { defaultParticipant } from '../../_lib/constant';
import { PrimaryRadio } from '../AtomComponents';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { getAttrLabel } from '../../_lib/lib';
import { isEmpty } from '../../_lib/util';

interface IWarehouseFilterProps {
  filtersInUseState: FilterInUserType;
  isPxnr: boolean;
  handleWarehouseChange: (e: any, level: number, filterName: string) => void;
  selectParticipant: (participantId: string) => void;
  curTenantSettings: any;
  menuDataState: any;
  stockListSettings: any;
  pxnWarehousesObj: { [key: string]: ParticipantWarehouse };
  participantsStocklistSettings: any;
  itemCounts: { [key: number]: number } | undefined;
  openWarehouseGroupDescriptionDialog: (participantId: any, type: 'info' | 'prompt') => () => void;
}

function WarehouseFilter({
  isPxnr,
  selectParticipant,
  handleWarehouseChange,
  pxnWarehousesObj,
  menuDataState,
  stockListSettings,
  filtersInUseState,
  curTenantSettings,
  participantsStocklistSettings,
  itemCounts,
  openWarehouseGroupDescriptionDialog,
}: IWarehouseFilterProps) {
  const I18n = React.useContext(I18nContext);

  const sortedParticipants = useMemo(() => {
    const participantsList = Object.keys(participantsStocklistSettings)
      .filter(
        (participant: any) =>
          (isPxnr ? participant in pxnWarehousesObj : true) &&
          !isEmpty(participantsStocklistSettings[participant].warehouse)
      )
      .sort((participant1: any, participant2: any) => {
        if (pxnWarehousesObj[participant1]?.isPxnr) return -1;
        if (pxnWarehousesObj[participant2]?.isPxnr) return 1;
        return 0;
      });
    return participantsList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantsStocklistSettings, pxnWarehousesObj]);

  const {
    hasMoreThanOneGroup,
    hasMoreThanOneWarehouse,
    warehousesWithAvailability,
  } = useMemo(() => {
    let hasMoreThanOneGroup = false,
      hasMoreThanOneWarehouse = false;

    const warehousesWithAvailability = stockListSettings.warehouse.filter(
      (warehouse: any) => !!menuDataState?.warehouse?.[warehouse]?._totals
    );

    hasMoreThanOneWarehouse = warehousesWithAvailability.length > 1;

    const participantsWithAvailablity = sortedParticipants.filter((participantId: any) =>
      isPxnr
        ? participantId in pxnWarehousesObj && !!itemCounts?.[participantId]
        : !!itemCounts?.[participantId]
    );

    // show warehouse group filter options, if selected warehouse group doesn't have availability
    const isParticipantWithAvailabilitySelected = !!participantsWithAvailablity.find(
      (participantId) => participantId === filtersInUseState?.Level0?.warehouseGroup
    );
    hasMoreThanOneGroup =
      participantsWithAvailablity.length > 1 || !isParticipantWithAvailabilitySelected;

    return { hasMoreThanOneGroup, hasMoreThanOneWarehouse, warehousesWithAvailability };
  }, [
    filtersInUseState.Level0.warehouseGroup,
    isPxnr,
    itemCounts,
    menuDataState.warehouse,
    pxnWarehousesObj,
    sortedParticipants,
    stockListSettings.warehouse,
  ]);

  // const warehousesHasItems = useMemo(() => {
  //   const warehousesHasItems = stockListSettings?.warehouse?.some((warehouse: any) => {
  //     const count = menuDataState?.warehouse?.[warehouse]?._totals || 0;
  //     return count > 0;
  //   });
  //   return warehousesHasItems;
  // }, [menuDataState, stockListSettings]);

  const setParticipant = (e: React.ChangeEvent<HTMLInputElement>) =>
    selectParticipant(e.target.value);
  if (!hasMoreThanOneGroup && !hasMoreThanOneWarehouse) return <></>;

  return (
    <>
      <h3>
        {I18n?.warehouse?.i18n_value || getAttrLabel(curTenantSettings, 'warehouse', 'Warehouse')}
      </h3>
      <RadioGroup
        aria-label={I18n?.warehouse?.i18n_value || 'Warehouse'}
        data-id="warehouse"
        name="warehouse"
        value={filtersInUseState.Level0.warehouseGroup}
        onChange={setParticipant}
      >
        {sortedParticipants?.map((participantId: any) => {
          // hide warehouse group if warehouse group is not listed
          const canShowGroup = isPxnr ? participantId in pxnWarehousesObj : true;
          const count = (itemCounts && itemCounts?.[participantId]) || 0;
          if (!count) return null;
          return (
            <React.Fragment key={participantId}>
              {participantId !== defaultParticipant && canShowGroup && hasMoreThanOneGroup && (
                <FormControlLabel
                  data-id={`px-participant-${participantId}`}
                  value={participantId}
                  control={<PrimaryRadio />}
                  disabled={!count}
                  style={{ display: 'flex', marginBottom: 5 }}
                  label={
                    <div className="px-warehouse-container">
                      {/* TODO: change tenantName to warehouseGroupName */}
                      <div className="px-warehouse-name">
                        {`${
                          pxnWarehousesObj[participantId]?.warehouseGroupName || participantId
                        } (${count})`}
                      </div>
                      {pxnWarehousesObj?.[participantId]?.warehouseGroupDescription && (
                        <IconButton
                          className="px-warehosue-group-info px-color-secondary"
                          onClick={openWarehouseGroupDescriptionDialog(participantId, 'info')}
                        >
                          <InfoOutlinedIcon />
                        </IconButton>
                      )}
                    </div>
                  }
                />
              )}
              {stockListSettings.pxParticipantId === participantId &&
                warehousesWithAvailability.length > 1 &&
                warehousesWithAvailability.map((warehouseNo: any) => {
                  let warehouse = `${warehouseNo}`;
                  const count = menuDataState?.warehouse?.[warehouse]?._totals || 0;
                  const selectedWarehouses = !!filtersInUseState.Level0.warehouse
                    ? filtersInUseState.Level0.warehouse
                        .substring(2, filtersInUseState.Level0.warehouse.length - 2)
                        .split('|><|')
                    : [];
                  if (!count) return null;
                  return (
                    <FormControlLabel
                      key={warehouse}
                      data-id={warehouse}
                      style={isPxnr && hasMoreThanOneGroup ? { marginLeft: '1rem' } : {}}
                      disabled={count <= 0}
                      value={warehouse}
                      control={
                        <Checkbox
                          name={`${warehouse}`}
                          data-id={warehouse}
                          onChange={(e: any) => {
                            handleWarehouseChange(e, 0, 'warehouse');
                          }}
                          checked={!!selectedWarehouses.includes(warehouse.toString())}
                          color="primary"
                        />
                      }
                      label={`${stockListSettings.warehouseLabels[warehouse]} (${count})`}
                    />
                  );
                })}
            </React.Fragment>
          );
        })}
      </RadioGroup>
    </>
  );
}

export default WarehouseFilter;
