import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOutTheUser } from '../../_lib/lib';

import Logo from '../Logo/Logo';
import { I18nContext } from '../../I18n';
import Dialog from '../Dialog/Dialog';
import MenuItem from '@material-ui/core/MenuItem';

import './TopMenu.scss';
import { PrimaryButton, TertiaryButton } from '../AtomComponents';
import { ClickAwayListener, MenuList, Popper } from '@material-ui/core';
import { isEmpty } from '../../_lib/util';
import { defaultParticipant } from '../../_lib/constant';
import { getConfigFromLocalStorage } from '../../hooks/useFetchConfig';
import AuthTopMenu from './AuthTopMenu';

interface IUserInfo {
  firstName: string;
  lastName: string;
  email: string;
  userId: number;
  isBuyer: boolean;
  isUserViewOnly?: boolean;
  enabledTags?: Array<string> | Record<string, boolean>;
  setupCompleted: boolean;
}

interface IPopOverMenu {
  element: HTMLElement;
  type: 'accountMenu' | 'buyerMenu';
}

function TopMenu(props: any) {
  const I18n = useContext(I18nContext);
  const [userData, setUserData] = useState<IUserInfo>();

  const isPendoInitialized = useRef<boolean>(false);

  const [popoverMenu, setPopOverMenu] = React.useState<IPopOverMenu>();
  const [logoutDialog, setLogoutDialog] = React.useState<any>();

  const getStringColorCode = (str: string) => {
    return str ? str[0].charCodeAt(0) : 0;
  };

  const fetchUserData = async () => {
    // calculate user initial color
    const userInfo = props.userState.userInfo;
    const elem: any = document.querySelector('nav.px-c-TopMenu');
    if (elem) {
      const colorNumber =
        Math.abs(
          (getStringColorCode(userInfo.firstName) + getStringColorCode(userInfo.lastName)) % 6
        ) + 1;
      elem.style.setProperty('--px_user_data_color', `var(--px_color_badge_${colorNumber})`);
    }
    setUserData(userInfo);
  };

  useEffect(() => {
    const getPxnParticipant = (pxnSettings: any) => {
      if (pxnSettings.isPxnr && pxnSettings.isPxns) return 'Supplier_Reseller';
      else if (pxnSettings.isPxnr) return 'Reseller';
      else return 'Supplier';
    };

    if (
      isEmpty(props.settings.inventory) ||
      isEmpty(props.settings.salesOrder) ||
      isEmpty(userData) ||
      isEmpty(props.settings.pxnsettings) ||
      isPendoInitialized.current
    )
      return;

    const newWindowObject = window as any;
    const pendo = newWindowObject.pendo;
    const ispxcParticipant = getConfigFromLocalStorage().networkParticipant;
    const participantId =
      ispxcParticipant && props.settings.pxnsettings && props.settings.pxnsettings.isPxnr
        ? +props.settings.pxnsettings.pxnrParticipantId
        : defaultParticipant;
    const orderSettings = props.settings.salesOrder[participantId];
    const visitor = {
      id: userData['userId'],
      role: 'Buyer',
      isBuyer: userData['isBuyer'],
      Inventory_Sync_Type: props.settings.inventory?.inventoryUpdateMethod,
      Inventory_Spdate_Source: props.settings.inventory?.inventoryUpdateSource,
      Order_Sync_Type: orderSettings?.salesOrderUpdateMethod,
      PXCParticipant: ispxcParticipant ? getPxnParticipant(props.settings.pxnsettings) : null,
    };
    const account = {
      id: `${props.configState.tenant_id}_StoreApp_${process.env.REACT_APP_STAGE}`,
    };
    // in your authentication promise handler or callback
    pendo.initialize({
      visitor,
      account,
    });
    isPendoInitialized.current = true;
  }, [props.settings, userData, props.configState.tenant_id]);

  useEffect(() => {
    if (props.userState?.isSignedIn && props.userState?.userInfo) {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userState?.isSignedIn, props.userState?.userInfo]);

  const openMenu = (e: any) => {
    setPopOverMenu({ element: e.target, type: 'buyerMenu' });
  };
  const openLogoutDialog = (e: any) => {
    closeMenu();
    setLogoutDialog(!!e);
  };

  const closeLogoutDialog = () => {
    closeMenu();
    setLogoutDialog(undefined);
  };

  const closeMenu = async () => {
    setPopOverMenu(undefined);
  };

  const logout = async () => {
    closeLogoutDialog();
    try {
      await logOutTheUser(
        I18n?.logoutSuccessMessage?.i18n_value || 'You logged out successfully!',
        'success'
      );
    } catch (error: any) {
      console.error('error signing out: ', error);
    }
  };

  return (
    <>
      <h1 className="show-for-sr text-center">
        {props.configState.tenantShortName || 'ACME'} SASS
      </h1>
      <nav className="px-c-TopMenu grid-cols-12">
        <Link to="/">
          <Logo />
        </Link>
        {/* show only LOGOUT button for prospects */}
        {(props.userState.requiresAccountSetup || (userData && !userData.setupCompleted)) && (
          <TertiaryButton onClick={openLogoutDialog} id="onboarding-logout">
            {I18n?.logout?.i18n_value || 'Logout'}
          </TertiaryButton>
        )}
        {/* show the regular menu for buyers who have completed the customer registration process */}
        {props.userState.isSignedIn && userData && userData.setupCompleted ? (
          <AuthTopMenu configState={props.configState} userData={userData} openMenu={openMenu} />
        ) : (
          <></>
        )}
      </nav>
      {popoverMenu && (
        <Popper
          open={!!popoverMenu.element}
          placement="bottom-start"
          className="px-popper-menu px-accounts-menu"
          disablePortal={true}
          transition
          keepMounted={false}
          anchorEl={popoverMenu.element}
        >
          <ClickAwayListener onClickAway={closeMenu}>
            <MenuList>
              <MenuItem data-id="accountDetailsBtn" component={Link} to="/account-details" onClick={closeMenu}>
                {I18n?.accountDetails?.i18n_value || 'My Account'}
              </MenuItem>
              <MenuItem data-id="myPreferencesBtn" component={Link} to="/preferences" onClick={closeMenu}>
                {I18n?.myPreferences?.i18n_value || 'My Preferences'}
              </MenuItem>
              <MenuItem data-id="logoutBtn" onClick={openLogoutDialog}>
                <span className="link">{I18n?.logout?.i18n_value || 'Logout'}</span>
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Popper>
      )}
      <Dialog
        isDialogOpen={!!logoutDialog}
        closeDialog={closeLogoutDialog}
        label={I18n?.logout?.i18n_value || 'Logout'}
        title={I18n?.logout?.i18n_value || 'Logout'}
        content={I18n?.logoutConfirmationMessage?.i18n_value || 'Are you sure you want to log out?'}
        actions={
          <>
            <TertiaryButton data-id="cancel" onClick={closeLogoutDialog}>
              {I18n?.cancel?.i18n_value || 'Cancel'}
            </TertiaryButton>
            <PrimaryButton data-id="confirm" onClick={logout}>{I18n?.logout?.i18n_value || 'Logout'}</PrimaryButton>
          </>
        }
      />
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    userState: state.userState,
    urlState: state.urlState,
    configState: state.configState,
    settings: {
      inventory: state.inventoryState?.settings,
      salesOrder: state.salesOrderSettingsState,
      pxnsettings: state.stockGroupState.pxnSettings,
    },
  };
}

export default connect(mapStateToProps)(TopMenu);
