import React, { useState, useEffect } from 'react';
import Dialog from '../../components/Dialog/Dialog';
import { Checkbox } from '@material-ui/core';
import { I18nContext } from '../../I18n';
import useDataService from '../../hooks/useDataService';
import apiToUrlMap from '../../ApiMapping';
import { TertiaryButton } from '../AtomComponents';

function DailyReportDialog(props: any) {
  const { isDialogOpen, onDailyReportOk, closeDialog } = props;

  const { fetchUrl } = useDataService();

  const [isChecked, setIsChecked] = useState(false);
  const I18n = React.useContext(I18nContext);

  useEffect(() => {
    let isRendering = true;

    async function getReportPreference() {
      try {
        const isCheckedObj = await fetchUrl('get', apiToUrlMap.stockDailyReport, {});

        if (isRendering) setIsChecked(isCheckedObj['stockReportNotification']);
      } catch (error) {
        console.log(error);
      }
    }

    if (isRendering) getReportPreference();

    return () => {
      isRendering = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title={I18n?.dailyReport?.i18n_value || 'Daily Report'}
      content={
        <div className="grid">
          <p className="cell small-12">
            {I18n?.keepUpWithWhatsNewInStockList?.i18n_value ||
              'Keep up with what’s new in the Stock List'}
          </p>
          <div className="cell grid-x align-middle">
            <Checkbox
              checked={isChecked}
              onChange={(e: any) => {
                setIsChecked(e.target.checked);
              }}
              color="primary"
              className="padding-left-0"
            />
            <label className="cell auto">
              {I18n?.receiveDailySpreadSheet?.i18n_value ||
                'Receive a daily spreadsheet report on all new inventory availability and price drops.'}
            </label>
          </div>
        </div>
      }
      actions={
        <TertiaryButton onClick={(e) => onDailyReportOk(e, isChecked)}>
          {I18n?.ok?.i18n_value || 'OK'}
        </TertiaryButton>
      }
    />
  );
}

export default DailyReportDialog;
