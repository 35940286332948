import { CloudUpload } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import apiToUrlMap from '../../ApiMapping';
import useDataService from '../../hooks/useDataService';
import { I18nContext } from '../../I18n';
import { closePOEDialog } from '../../redux/actions/userActions';
import { eMessageType } from '../../types/IMessageType';
import { IPOEReduxState, IPOEServerResponse } from '../../types/POETypes';
import { PrimaryButton, TertiaryButton } from '../AtomComponents';
import FileWell from '../AtomComponents/FileWell';
import Dialog from '../Dialog/Dialog';

function ProofOfExportDialog({ userState, dispatch }: any) {
  const I18n = React.useContext(I18nContext);
  const [files, setFiles] = React.useState<{ [key: string]: File }>();
  const unauthorizedResponseCount = React.useRef(0);
  const { fileUpload, openSnackBar, fetchUrl } = useDataService();
  const poeData: IPOEReduxState = userState.poeData;
  const closeDialog = () => {
    setFiles(undefined);
    dispatch(closePOEDialog());
  };
  const handleDrop = (file: any, docId: number) => {
    const updatedFiles = { ...files };
    updatedFiles[docId] = file[0];
    setFiles(updatedFiles);
  };

  const generateFilesToUpload = (filesData: undefined | { [key: string]: File }) => {
    if (!filesData) return [];
    return Object.keys(filesData)
      .filter((docId: string) => !!filesData[docId])
      .reduce((acc, docId: string) => {
        const res = filesData[docId];
        acc.append('files', res, `${docId}__${res.name}`);
        return acc;
      }, new FormData());
  };

  const uploadDocuments = async (filesToUpload: FormData | never[], url: string) => {
    if (!filesToUpload || (Array.isArray(filesToUpload) && filesToUpload.length === 0))
      return openSnackBar(
        I18n.pleaseUploadProofOfDocument?.i18n_value || 'Please upload Proof of Export Document.',
        eMessageType.error
      );
    // fileUpload
    const token = poeData.authToken;

    const headers = [
      {
        header: 'poe-upload-token',
        value: token,
      },
    ];

    return await fileUpload(filesToUpload, url, headers, undefined, true, { fileName: "files" });
  };

  // show user proof of document dialog
  const fetchProofOfExport = async () => {
    try {
      const proofOfExportData: IPOEServerResponse = await fetchUrl(
        'get',
        apiToUrlMap.getProofOfExportPendingDocuments,
        {}
      );
      return proofOfExportData;
    } catch (err) {
      console.error('error in fetching proof of export', err);
      throw err;
    }
  };

  const uploadDocumentAndShowMessage = async (url: string) => {
    if (!files || Object.keys(files).every((docId) => !files[docId]))
      return openSnackBar(
        I18n.proofOfExportNotFilesUploadedSnackbar?.i18n_value ||
          'Please upload at least one Proof of Export Document.',
        eMessageType.error
      );
    try {
      // call save document api and show response in dialog
      await uploadDocuments(generateFilesToUpload(files), url);
      // show success message
      openSnackBar(
        I18n.proofOfExportUploadSuccessSnackbar?.i18n_value ||
          'Proof of Export Document uploaded successfully. We’ll notify you by email after we verify the docs you have uploaded.',
        eMessageType.success
      );
      // and close the dialog after upload
      closeDialog();
    } catch (error) {
      //  if upload is unauthorized then refetch the documents and use the url to upload the file again
      if (error.status === 401) {
        unauthorizedResponseCount.current += 1;
        if (unauthorizedResponseCount.current === 1) {
          // refetch the documents
          const res = await fetchProofOfExport();
          // retry uploading the files
          if (res) {
            await uploadDocumentAndShowMessage(res.documentUploadUrl);
            return;
          }
        }
      }

      openSnackBar(
        I18n.proofOfExportUploadErrorSnackbar?.i18n_value ||
          'Proof of Export Document uploaded failed! Please try again.',
        eMessageType.error
      );
    }
  };

  const saveFiles = async () => {
    // if documents are not present then close the dialog
    if (poeData.documents.length === 0) return closeDialog();
    await uploadDocumentAndShowMessage(poeData.documentUploadUrl);
  };

  if (!poeData) return <></>;
  return (
    <Dialog
      isDialogOpen={!!poeData.isOpen}
      closeDialog={closeDialog}
      showScroll={true}
      title={
        <div className="px-align-middle">
          <CloudUpload className="margin-right-1" />{' '}
          {I18n?.poeDialogTitle?.i18n_value || 'Provide Proof of Export Document'}
        </div>
      }
      content={
        <>
          <p className="px-text-description">
            {I18n?.poeDialogDescription?.i18n_value ||
              'Please upload proof-of-export docs (e.g. Air-Waybill of Bill-of-Lading) for your previously shipped orders so we can be ready to ship your next order.'}
          </p>
          <h4>{I18n.proofOfExport?.i18n_value || 'Proof of Export'}</h4>
          {poeData.documents.length ? (
            <div id="px-poe-document-list">
              {poeData.documents.map((doc) => {
                return (
                  <div key={doc.documentId} className="px-poe-document">
                    <p>{doc.documentName}</p>
                    {doc.documentDescription.map((val) => (
                      <p key={val}>{val}</p>
                    ))}
                    <FileWell
                      filesLimit={1}
                      handleDrop={(file: any) => {
                        handleDrop(file, doc.documentId);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <p>{I18n.thankYouExclamation?.i18n_value || 'Thank You!'}</p>
              <p>
                {I18n.allDocumentsUploadedSuccessFullyMsg?.i18m_value ||
                  'We’ll notify you by email after we verify the docs you have uploaded.'}
              </p>
            </>
          )}
        </>
      }
      actions={
        <>
          {poeData.documents.length && (
            <TertiaryButton onClick={closeDialog}>
              {I18n.cancel?.i18m_value || 'Cancel'}
            </TertiaryButton>
          )}
          <PrimaryButton onClick={saveFiles}>{I18n.ok?.i18n_value || 'OK'}</PrimaryButton>
        </>
      }
    />
  );
}

function mapStateToProps(state: any) {
  return {
    userState: state.userState,
  };
}

export default connect(mapStateToProps)(ProofOfExportDialog);
