import { ArrowBackIos, Email } from '@material-ui/icons';
import { format } from 'date-fns';
import React from 'react';
import { logOutTheUser } from '../../_lib/lib';
import { PrimaryButton, SecondaryButton } from '../AtomComponents';
import { CopyIcon } from '../../_lib/icons';
import './ErrorView.scss';
import { getConfigFromLocalStorage } from '../../hooks/useFetchConfig';

export interface IErrorView {
  error: {
    message: string;
    status: string;
    traceId: string;
  };
  openSnackBar?: any;
}

const ErrorView = (params: IErrorView) => {
  const config = getConfigFromLocalStorage();
  const status = params.error.status;
  const pathname = window.location.pathname
    .split('/')
    .filter((w) => w)
    .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');
  const domain = document.location.origin;
  const traceId = params.error.traceId || '--';
  const dateTimeOfError = `${format(new Date(), 'MMM dd hh:mm a')} ${new Date()
    .toString()
    .split('(')[1]
    ?.replace(')', '')}`;
  const email = config?.clientSupportEmail || '';
  const errorDetails = `
    Recorded time of error: ${dateTimeOfError}\t\n
    Error Code: ${status}\t\n
    Domain: ${domain}\t\n
    Page: ${pathname}\t\n
    Trace ID: ${traceId}\t\n
  `;
  const emailLink = `mailto:${email}?subject=Error%20Report%20from%20${domain}&body=${errorDetails.replace(
    /\\t|\\n/g,
    '%0D'
  )}`;
  const pxCopyToClipboard = async () => {
    await navigator.clipboard.writeText(errorDetails);
    params.openSnackBar && params.openSnackBar('Copied to clipboard', 'success');
  };

  return (
    <div className="px-error-view">
      <h2>Sorry, we ran into an issue!</h2>
      <h4>We’re sorry, but an error occurred while processing your request.</h4>
      {email && (
        <h6 className="px-text-description">
          You can copy the details of this error and send them to {email}.
        </h6>
      )}
      <div className="grid-x">
        <div className="cell small-3 px-text-description">Recorded time of error:</div>
        <div className="cell auto">{dateTimeOfError}</div>
      </div>
      <div className="grid-x">
        <div className="cell small-3 px-text-description">Error Code:</div>
        <div className="cell auto">{status}</div>
      </div>
      <div className="grid-x">
        <div className="cell small-3 px-text-description">Domain:</div>
        <div className="cell auto">{domain}</div>
      </div>
      <div className="grid-x">
        <div className="cell small-3 px-text-description">Page:</div>
        <div className="cell auto">{pathname}</div>
      </div>
      <div className="grid-x">
        <div className="cell small-3 px-text-description">Trace ID:</div>
        <div className="cell auto">{traceId}</div>
      </div>
      <div className="grid-x px-action-btn">
        <SecondaryButton onClick={pxCopyToClipboard}>
          <CopyIcon />
          &nbsp; COPY TO CLIPBOARD
        </SecondaryButton>
        <SecondaryButton href={emailLink}>
          <Email />
          &nbsp; {email}
        </SecondaryButton>
      </div>
      <div className="grid-x">
        <PrimaryButton
          onClick={() => logOutTheUser('Logout successfully', 'success')}
          className="px-logout-btn"
        >
          <ArrowBackIos />
          RETURN TO SIGN IN
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ErrorView;
