import React, { useState, useEffect } from 'react';
import StockListItem from './StockListItem';
import './StockList.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as stockActions from '../../redux/actions/stockGroupActions';
import { I18nContext } from '../../I18n';
import useDataService from '../../hooks/useDataService';
import apiToUrlMap from '../../ApiMapping';
import { checkNullOrUndefined } from '../../_lib/lib';
import { useHistory } from 'react-router-dom';
import { canBuyOnlineFn, isViewOnlyCustomer } from '../../_lib/tagUtil';

const getExpiringOffersCount = (offers: any) => {
  const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
  const currentTimeInMilliSeconds = new Date().getTime();

  const countOfOffersWithin24Hours = Object.values(offers).reduce((count: number, offer: any) => {
    const offerExpirationDate = offer.stocklistOffer?.acceptedOrCounteredExpirationDate;
    if (!offerExpirationDate) return count;
    const expirationDateInMilliSeconds = new Date(offerExpirationDate).getTime();
    const timeRemaining = expirationDateInMilliSeconds - currentTimeInMilliSeconds;
    const isTimeLessThan24Hours =
      timeRemaining >= 0 && timeRemaining < twentyFourHoursInMilliseconds;
    return isTimeLessThan24Hours ? (count += 1) : count;
  }, 0);
  return countOfOffersWithin24Hours;
};

const getCountOfOrdersExpiring = (salesOrders: any) => {
  let countOnHoldOrdersExpiring = 0,
    countAwaitingPaymentOrdersPastDue = 0;
  salesOrders.forEach((salesOrder: any) => {
    if (checkNullOrUndefined(salesOrder.daysTillPaymentDeadline)) return;
    const expiringIn3Days = salesOrder.daysTillPaymentDeadline < 3;
    if (salesOrder.fulfillmentStatus === 'ON_HOLD' && expiringIn3Days)
      countOnHoldOrdersExpiring += 1;
    if (salesOrder.fulfillmentStatus === 'AWAITING_PAYMENT' && expiringIn3Days)
      countAwaitingPaymentOrdersPastDue += 1;
  });
  return { countOnHoldOrdersExpiring, countAwaitingPaymentOrdersPastDue };
};

function StockList(props: any) {
  const I18n = React.useContext(I18nContext);
  const { fetchUrl } = useDataService();
  const [stockAlerts, setStockAlerts] = useState<any>({});

  const history = useHistory();

  const {
    pageDataState,
    stockListOffers,
    stockListSettings,
    pxnSettings,
    setCartNumber,
    actions,
    authorities,
    enabledTags,
    setupCompleted,
    setAlertsBadgeNumber,
    openGradePopup,
    isPxns,
    snackbarRefs,
    ...rest
  } = props;
  //const [offers, setOffers] = React.useState(stockListOffers);

  const displaySnackBars = (offers: any, salesOrders: any) => {
    const {
      awaitingOffersSnackbarRef,
      expiringAwaitingPaymentOrdersRef,
      expiringOnHoldOrdersSnackbarRef,
    } = snackbarRefs;
    const countOfOfferExpiring = getExpiringOffersCount(offers);
    const {
      countAwaitingPaymentOrdersPastDue,
      countOnHoldOrdersExpiring,
    } = getCountOfOrdersExpiring(salesOrders);

    if (countOfOfferExpiring) {
      awaitingOffersSnackbarRef?.current?.open(
        `${countOfOfferExpiring} of your Offers are Expiring Soon.`,
        {
          label: <span className="px-bold-600"> SEE OFFERS</span>,
          handler: () => {
            awaitingOffersSnackbarRef.current?.close();
            history.push('/cart');
          },
          variant: 'text',
        }
      );
    }
    if (countOnHoldOrdersExpiring) {
      expiringOnHoldOrdersSnackbarRef?.current?.open(
        `${countOnHoldOrdersExpiring} of your On Hold Orders are Expiring Soon.`,
        {
          label: <span className="px-bold-600"> SEE ORDERS</span>,
          handler: () => {
            expiringOnHoldOrdersSnackbarRef.current?.close();
            history.push('/orders');
          },
          variant: 'text',
        }
      );
    }
    if (countAwaitingPaymentOrdersPastDue) {
      expiringAwaitingPaymentOrdersRef.current?.open(
        `${countAwaitingPaymentOrdersPastDue} of your Awaiting Payment Orders will be Past Due soon.`,
        {
          label: <span className="px-bold-600"> SEE ORDERS</span>,
          handler: () => {
            expiringAwaitingPaymentOrdersRef.current?.close();
            history.push('/orders');
          },
          variant: 'text',
        }
      );
    }
    localStorage.removeItem('showStockListNotifications');
  };

  useEffect(() => {
    if (checkNullOrUndefined(setupCompleted)) return;
    //call get offers and alerts for buyers who have completed the customer registration
    if (!setupCompleted) return;
    async function getOffers() {
      try {
        const offers = await fetchUrl('GET', apiToUrlMap.offers, {});
        actions.stockOffersUpdate(offers || {});
        const showSnackbars = localStorage.getItem('showStockListNotifications');
        if (showSnackbars) {
          const salesOrders = await fetchUrl('get', apiToUrlMap.getOrdersV1, {});
          displaySnackBars(offers, salesOrders);
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function getAlerts() {
      try {
        const alerts = await fetchUrl('get', apiToUrlMap.stockAlerts, {});
        setStockAlerts(alerts);
      } catch (err) {
        console.log(err);
      }
    }
    const canBuyOnline = canBuyOnlineFn(authorities);
    if (canBuyOnline && !isViewOnlyCustomer(enabledTags)) getOffers();
    if (authorities['CAN_SET_STOCKLIST_ALERT']) getAlerts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorities, setupCompleted]);

  const onUpdateStockAlerts = (itemNumberWarehouse: string, payload: any) => {
    setStockAlerts({
      ...stockAlerts,
      [itemNumberWarehouse]: payload,
    });
  };

  useEffect(() => {
    // setOffers(stockListOffers);
    if (stockListOffers) setCartNumber(Object.keys(stockListOffers).length);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockListOffers]);

  useEffect(() => {
    if (stockAlerts) setAlertsBadgeNumber(Object.keys(stockAlerts).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockAlerts]);

  return (
    <>
      {pageDataState.length > 0 ? (
        <div className="grid-x">
          {pageDataState.map((group: any) => {
            return (
              <StockListItem
                key={group.key}
                group={group}
                title={group.title}
                stockListSettings={stockListSettings}
                isPxns={isPxns}
                {...rest}
                offers={stockListOffers || {}}
                stockAlerts={stockAlerts}
                setCartNumber={setCartNumber}
                stockOffersUpdate={actions.stockOffersUpdate}
                stockAlertsUpdate={onUpdateStockAlerts}
                canUserBuy={canBuyOnlineFn(authorities)}
                showBuyOnlineBtn={!isViewOnlyCustomer(enabledTags)}
                canUserAlert={authorities['CAN_SET_STOCKLIST_ALERT']}
                openGradePopup={openGradePopup}
                setupCompleted={setupCompleted}
              />
            );
          })}
        </div>
      ) : (
        <div className="margin-vertical-3">
          {I18n?.noItemsFound?.i18n_value || 'No items found!'}
        </div>
      )}
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    stockListOffers: state.stockGroupState.offers,
    authorities: state.userState.authorities,
    enabledTags: state.userState.userInfo?.enabledTags,
    setupCompleted: state.userState.userInfo?.setupCompleted,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(stockActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StockList);
