import React, { useContext } from 'react';
import Dialog from '../../components/Dialog/Dialog';
import { I18nContext } from '../../I18n';
import { TertiaryButton } from '../AtomComponents';

function StockListTutorialDialog(props: any) {
  const I18n = useContext(I18nContext);
  const { isDialogOpen, closeDialog, url } = props;

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title={I18n.tutorial?.i18n_value || 'Tutorial'}
      fullWidth={true}
      content={
        <div className="cell grid-x" style={{ fontSize: 0, height: 512 }}>
          <iframe
            title="StockList Tutorial"
            src={url}
            width={'100%'}
            height={'100%'}
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      }
      actions={
        <TertiaryButton onClick={closeDialog}>{I18n.close?.i18n_value || 'CLOSE'}</TertiaryButton>
      }
    />
  );
}

export default StockListTutorialDialog;
