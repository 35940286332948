import React, { useContext } from 'react';

import { PrimaryButton, SecondaryButton, TertiaryButton } from '../AtomComponents';

import { I18nContext } from '../../I18n';
import * as constants from '../../types/enumToLabelsMap';
import { hideStockListItemPrices, showSalesOptions } from './StockListUtils';

interface IStockListBuyOffer {
  offers: any;
  item: any;
  canUserBuy: boolean;
  canOffer: boolean;
  settings: any,
  openOrClosePopup: (
    isOfferDialog: boolean,
    isEditMode: boolean,
    isOpenPopup: boolean,
    item: any
  ) => void;
  groupDescription: string;
  isPxns: boolean;
  showBuyOnlineBtn: any;
  categorySaleOption?: Record<string, 'OFFER' | 'BUY' | 'BOTH'>;
}

interface IOfferInCart {
  offers: any;
  item: any;
  I18n: any;
  openOrClosePopup: (
    isOfferDialog: boolean,
    isEditMode: boolean,
    isOpenPopup: boolean,
    item: any
  ) => void;
  groupDescription: string;
}

const StockListBuyOffer = ({
  offers,
  item,
  settings,
  canUserBuy,
  canOffer,
  showBuyOnlineBtn,
  openOrClosePopup,
  groupDescription,
  categorySaleOption,
  isPxns,
}: IStockListBuyOffer) => {
  const I18n = useContext(I18nContext);

  if (isPxns && item.pendingOrderItemCount > 0)
    return <PendingOrder count={item.pendingOrderItemCount} I18n={I18n} />;

  if (!showBuyOnlineBtn) return <></>;

  const { buy, offer } = showSalesOptions(categorySaleOption, item);
  return !offers[item.itemNumberWarehouse] ? (
    <>
      {buy && (
        <PrimaryButton
          size="small"
          data-id="buy"
          disableElevation
          className="px-item-buyOffer vertical-middle"
          disableRipple
          disabled={!canUserBuy}
          onClick={() => {
            openOrClosePopup(false, false, true, {
              ...item,
              groupDescription,
              offerQuantity: item.incrementSize,
              offerPrice: item.specialPrice || item.listPrice,
            });
          }}
        >
          {I18n?.buy?.i18n_value || 'Buy'}
        </PrimaryButton>
      )}
      &nbsp;
      {canOffer && offer && (
        <SecondaryButton
          size="small"
          data-id="offer"
          className="px-item-buyOffer vertical-middle"
          disableRipple
          disabled={!canUserBuy}
          onClick={() => {
            openOrClosePopup(true, false, true, {
              ...item,
              offerQuantity: item.incrementSize,
              offerPrice: !hideStockListItemPrices(settings, item.category)
                ? item.specialPrice || item.listPrice
                : '',
              groupDescription,
            });
          }}
        >
          {I18n?.offer?.i18n_value || 'Offer'}
        </SecondaryButton>
      )}
    </>
  ) : (
    <OfferInCart
      item={item}
      I18n={I18n}
      openOrClosePopup={openOrClosePopup}
      offers={offers}
      groupDescription={groupDescription}
    />
  );
};

const OfferInCart = ({ offers, item, openOrClosePopup, groupDescription, I18n }: IOfferInCart) => {
  return (
    <TertiaryButton
      disableRipple
      className={'vertical-middle padding-0 px-no-bg px-link-btn'}
      style={{ marginTop: '5px' }}
      data-id="offersInCart"
      onClick={() =>
        openOrClosePopup(
          offers[item.itemNumberWarehouse].stocklistOffer['offerStatus'] !== constants.BUY_AT_LIST,
          true,
          true,
          {
            ...item,
            groupDescription,
            offerQuantity:
              offers[item.itemNumberWarehouse].stocklistOffer['offerStatus'] ===
              constants.BUY_AT_LIST
                ? offers[item.itemNumberWarehouse].stocklistOffer.checkOutQuantity
                : offers[item.itemNumberWarehouse].stocklistOffer.offerQuantity,
            offerPrice: offers[item.itemNumberWarehouse].stocklistOffer.offerPrice,
            offerState: offers[item.itemNumberWarehouse].stocklistOffer.offerState,
            offerStatus: offers[item.itemNumberWarehouse].stocklistOffer.offerStatus,
            offerId: offers[item.itemNumberWarehouse].stocklistOffer.offerId,
            minimumCheckoutQuantity:
              offers[item.itemNumberWarehouse].stocklistOffer.minimumCheckoutQuantity,
            acceptedOrCounteredExpirationDate:
              offers[item.itemNumberWarehouse].stocklistOffer.acceptedOrCounteredExpirationDate,
            counterPrice: offers[item.itemNumberWarehouse].stocklistOffer.counterPrice,
            incrementSize: offers[item.itemNumberWarehouse].incrementSize,
          }
        )
      }
    >
      <span>
        {offers[item.itemNumberWarehouse].stocklistOffer['offerStatus'] === constants.BUY_AT_LIST
          ? I18n._format(I18n?.xInCart?.i18n_value || '${1} IN CART', [
              offers[item.itemNumberWarehouse].stocklistOffer['offerStatus'] !==
              constants.PENDING_ACCEPTANCE
                ? offers[item.itemNumberWarehouse].stocklistOffer['checkOutQuantity']
                : offers[item.itemNumberWarehouse].stocklistOffer['offerQuantity'],
            ])
          : I18n._format(I18n?.xOffersInCart?.i18n_value || '${1} OFFERS IN CART', [
              offers[item.itemNumberWarehouse].stocklistOffer['offerStatus'] !==
              constants.PENDING_ACCEPTANCE
                ? offers[item.itemNumberWarehouse].stocklistOffer['checkOutQuantity']
                : offers[item.itemNumberWarehouse].stocklistOffer['offerQuantity'],
            ])}
      </span>
    </TertiaryButton>
  );
};

export const PendingOrder = ({ count, I18n }: { count: number; I18n: any }) => {
  return (
    <TertiaryButton
      disableRipple
      className={'vertical-middle padding-0 px-no-bg px-link-btn'}
      style={{ marginTop: '5px' }}
      data-id="pendingOrder"
      onClick={() => {}}
    >
      {I18n._format(I18n?.orderPending?.i18n_value || '${1} order pending', [count])}
    </TertiaryButton>
  );
};

export default StockListBuyOffer;
