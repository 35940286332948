import { API, Auth } from 'aws-amplify';
import * as constants from '../types/enumToLabelsMap';
import {
  IBillingShippingDefaults,
  IShippingUI,
  IShippingValidationPair,
  validatorType,
} from '../types/IBillingShippingTypes';
import { format, intervalToDuration } from 'date-fns';
import { getConfigFromLocalStorage } from '../hooks/useFetchConfig';
// const apiName = 'pxGatewayTestAPI';
export const apiName = 'tenantSpecificEndpoint';

const extraParams = {
  headers: {
    // "pxTestAPIHeader": "pxTestAPIHeaderVal"
  },
};

const fetchConfig = async (env: any) => {
  let publicDataEndpoint;
  publicDataEndpoint = process.env.REACT_APP_API_URL + '/px-api-gateway/saas-config';
  if (!process.env.REACT_APP_API_URL)
    switch (env) {
      case 'test':
        publicDataEndpoint =
          'https://s4z4motqq3.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-config';
        break;
      case 'prod':
        publicDataEndpoint =
          'https://qkexusfqqf.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-config';
        break;
      case 'release':
        publicDataEndpoint =
          'https://94o107xk88.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-config';
        break;
      default:
      // publicDataEndpoint = process.env.REACT_APP_API_URL + '/px-api-gateway/saas-config';
    }
  const res = await fetch(publicDataEndpoint);
  const data = await res.json();
  if (data.status === 200) return data.results;
  else return {};
};

export const getConfig = async () => {
  const remoteConfig = await fetchConfig(process.env.REACT_APP_STAGE);
  const parsedConfig = getConfigFromLocalStorage();
  // always set remote config, but only when it doesn't exist, or versions do not match local
  if (!parsedConfig.last_modified) {
    localStorage.setItem('PxConfig', JSON.stringify(remoteConfig));
    // refresh I18n whenever config is updated.
    localStorage.removeItem('PxI18n');
    await getI18n();
    return remoteConfig;
  }
  if (parsedConfig.last_modified !== remoteConfig.last_modified) {
    localStorage.setItem('PxConfig', JSON.stringify(remoteConfig));
    // refresh I18n whenever config is updated.
    localStorage.removeItem('PxI18n');
    await getI18n();
  }
  return remoteConfig;
};

export const getUser = () => {
  return new Promise((resolve: any) => {
    const localUser = localStorage.getItem('PxUser');
    if (localUser) {
      resolve(JSON.parse(localUser));
    } else {
      resolve(null);
    }
  });
};

export const getFilter = () => {
  return new Promise((resolve: any) => {
    const localFilter = localStorage.getItem('PxFilter');
    if (localFilter) {
      resolve(JSON.parse(localFilter));
    } else {
      const path = '/px-api-gateway/filter';
      API.get(apiName, path, extraParams).then(async (res: any) => {
        localStorage.setItem('PxFilter', JSON.stringify(res));
        resolve(res);
      });
    }
  });
};

export const getInventorySettings = async () => {
  const path = `/px-api-gateway/inventory/settings`;
  const settings = await commonService('get', path, {});
  return settings;
};

export const logoutUserWithoutAcknowledgement = async () => {
  try {
    await Auth.signOut();
    localStorage.clear();
    window.indexedDB.deleteDatabase('d2');
  } catch (error: any) {
    console.error('error signing out: ', error);
  }
};

export const logOutTheUser = async (
  message: string,
  severity: any,
  messageType?: any,
  cacheSave?: boolean,
  cb?: Function
) => {
  try {
    await Auth.signOut();
    if (!cacheSave) {
      localStorage.clear();
      window.indexedDB.deleteDatabase('d2');
    }
    localStorage.setItem(
      'customLoginLoadMessage',
      JSON.stringify({ message, severity, messageType })
    );
    cb && cb();
    document.location.href = '/';
  } catch (error: any) {
    console.error('error signing out: ', error);
  }
};

export const getGroupStock = () => {
  return new Promise(async (resolve: any) => {
    performance.mark('getGroupStock:start');
    console.log('getGroupStock');
    const path = '/px-api-gateway/stocklist/items';
    API.get(apiName, path, extraParams).then(async (res: any) => {
      //localStorage.setItem('PxStockTotalCount', res.totalCount);
      //ldb.set('PxStockGroup', JSON.stringify(res.results));
      console.log('getGroupStock - got from remote');
      resolve(res);
      performance.mark('getGroupStock:end');
      console.log(performance.measure('getGroupStock', 'getGroupStock:start', 'getGroupStock:end'));
    });
  });
};

export const getI18n = () => {
  return new Promise((resolve: any) => {
    const getI18nByLang = (data: any) => {
      const localeFromConfig = localStorage.getItem('Px-localeFromConfig');
      const browserLocale = navigator.languages ? navigator.languages : [navigator.language];
      let chosenLocale: any = null;
      if (localeFromConfig && data.hasOwnProperty(localeFromConfig)) {
        // If user chose a locale from config, use it
        chosenLocale = localeFromConfig;
      } else {
        // Or try using the locale from browser
        Object.keys(data).forEach((i18nLang: string) => {
          if (!chosenLocale) {
            browserLocale.forEach((l: string) => {
              if (!chosenLocale && i18nLang.indexOf(l) > -1) {
                chosenLocale = i18nLang;
              }
            });
          }
        });
      }
      const localConfig: any = getConfigFromLocalStorage();
      chosenLocale = localConfig?.language;
      if (chosenLocale && !localeFromConfig) {
        localStorage.setItem('Px-localeFromConfig', chosenLocale);
      }
      if (chosenLocale) {
        document.documentElement.lang = chosenLocale;
        return data[chosenLocale];
      }
      return {};
    };

    // Try loading i18n from localStorage
    const localI18n = localStorage.getItem('PxI18n');
    if (localI18n) {
      const i18n = getI18nByLang(JSON.parse(localI18n));
      resolve(i18n);
    } else {
      // console.log('rocess.env.REACT_APP_STAGE ' + process.env.REACT_APP_STAGE);
      let publicDataEndpoint = process.env.REACT_APP_API_URL + '/px-api-gateway/saas-i18n';
      if (!process.env.REACT_APP_API_URL) {
        if (process.env.REACT_APP_STAGE === 'test') {
          publicDataEndpoint =
            'https://s4z4motqq3.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-i18n';
        } else if (process.env.REACT_APP_STAGE === 'prod') {
          publicDataEndpoint =
            'https://qkexusfqqf.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-i18n';
        } else if (process.env.REACT_APP_STAGE === 'release') {
          publicDataEndpoint =
            'https://94o107xk88.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-i18n';
        }
      }
      fetch(publicDataEndpoint).then(async (res: any) => {
        const data = await res.json();
        if (data.status === 200) {
          const remoteI18n = Object.assign({}, data.results);
          let i18n: any = {};
          if (Object.keys(remoteI18n).length) {
            // Only store i18n if it had content
            localStorage.setItem('PxI18n', JSON.stringify(remoteI18n));
            i18n = getI18nByLang(remoteI18n);
          }
          resolve(i18n);
        } else {
          resolve({});
        }
      });
    }
  });
};

export const stringTemplateFormatter = (text: string, variables: Array<any>) => {
  let result = text;
  const count = variables.length;
  for (let i = 1; i <= count; i++) {
    result = result.replace('${' + i + '}', variables[i - 1]);
  }
  return result;
};

export const toCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/['"]/g, '')
    .replace(/\W+/g, ' ')
    .replace(/ (.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/ /g, '');
};

export const commonService = async (
  method: string,
  path: string,
  additionalParams: any,
  permission?: string
) => {
  try {
    let result: any;
    const upperCaseMethod = method.toUpperCase();
    switch (upperCaseMethod) {
      case 'GET': {
        result = await API.get(apiName, path, additionalParams);
        break;
      }
      case 'POST': {
        result = await API.post(apiName, path, additionalParams);
        break;
      }
      case 'PUT': {
        result = await API.put(apiName, path, additionalParams);
        break;
      }
      case 'PATCH': {
        result = await API.patch(apiName, path, additionalParams);
        break;
      }
      case 'DELETE': {
        result = await API.del(apiName, path, additionalParams);
        break;
      }
      default: {
        result = {};
      }
    }

    if (upperCaseMethod === 'POST' && result?.id) {
      // you'll get id here
      return {
        ...result,
        success: true,
      };
    }

    if (upperCaseMethod === 'POST' && !result) {
      return {
        success: true,
      };
    }

    if (upperCaseMethod === 'PUT' || upperCaseMethod === 'DELETE') {
      if (!result || (typeof result === 'object' && Object.keys(result).length === 0)) {
        return {
          success: true,
        };
      }
    }

    if (upperCaseMethod === 'GET' && (!result.status || result.status === 200)) {
      return result;
    }

    if (!result) {
      return {
        status: 500,
        message: 'Internal Service Error',
        error: true,
      };
    }
    return {
      status: result.statusCode,
      message: result.message,
      error: true,
    };
  } catch (e: any) {
    console.log(e, 'error occurred at common service lib');
    if (e.code === 'NotAuthorizedException') {
      // redirect
      // <Redirect />;
      return;
    }
    return {
      timestamp: Date.now(),
      status: e.status,
      error: 'Connection Error',
      message: e.message,
      path: path,
    };
  }
};

export const formatBuyerName = (buyerName: string) => {
  return buyerName?.substr(0, 30) + (buyerName.length > 30 ? '...' : '');
};

export const getBuyerFullName = (userInfo: any) => {
  let fullName = '';
  if (userInfo?.firstName) fullName = userInfo.firstName + ' ';
  if (userInfo?.lastName) fullName += userInfo.lastName;
  return fullName;
};

export const formatExpiryUTCString = (acceptedOrCounteredExpirationDate: any) => {
  if (!acceptedOrCounteredExpirationDate) return null;
  const diffDate = intervalToDuration({
    start: new Date(acceptedOrCounteredExpirationDate),
    end: new Date(),
  });
  return `${diffDate.days ? `${diffDate.days}d ` : ''} ${
    diffDate.hours ? `${diffDate.hours}h ` : ''
  } ${diffDate.minutes ? `${diffDate.minutes}m ` : ''}`.trim();
};

export const offerStatusExtraInfoValues: any = (
  offerStatus: any,
  computedOfferPrice: any,
  minimumCheckoutQuantity: any,
  acceptedOrCounteredExpirationDate: any,
  counterPrice: any
) => {
  switch (offerStatus) {
    case constants.ACCEPTED:
      return [
        computedOfferPrice,
        minimumCheckoutQuantity,
        formatExpiryUTCString(acceptedOrCounteredExpirationDate),
      ];
    case constants.PRICE_DROPPED:
      return [];
    case constants.COUNTERED:
      return [
        computedOfferPrice,
        counterPrice,
        minimumCheckoutQuantity,
        formatExpiryUTCString(acceptedOrCounteredExpirationDate),
      ];
    case constants.NOT_ACCEPTED:
    case constants.EXPIRED:
      return [computedOfferPrice];
    default:
      return [];
  }
};

export const downloadFile = async (file: any, fileName?: string) => {
  const name = file.headers['content-disposition']
    ? file.headers['content-disposition'].split('=')[1].trim()
    : fileName;
  const href = window.URL.createObjectURL(file.data);
  const link = document.createElement('a');
  link.href = href;
  link.style.display = 'none';
  link.setAttribute('download', name); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(href);
  return true;
};

export const getAttrLabel = (settings: Record<string, any>, attr: string, defaultStr: string) => {
  const labels: Record<string, string> = settings?.attributeLabels;
  if (labels && attr && labels.hasOwnProperty(attr) && labels[attr]) return labels[attr];
  return defaultStr;
};

export const formatISODate = (date: string) => {
  return date
    .split(' ')
    .filter((_: any, idx: number) => {
      return idx < 4;
    })
    .join(' ');
};

export const generateAddressParts = (state: string, nextState: string) => {
  return state ? state + (nextState ? ', ' : '') : '';
};

const reN = /[^0-9]/g;
const reA = /[^a-zA-Z]/g;

const sortAlphaNum = (a: any, b: any, sort: 'asc' | 'desc') => {
  let aA = a.replace(reA, '');
  let bA = b.replace(reA, '');
  if (aA === bA) {
    let aN = parseInt(a.replace(reN, ''), 10);
    let bN = parseInt(b.replace(reN, ''), 10);
    return aN === bN ? 0 : aN > bN ? (sort === 'asc' ? 1 : -1) : sort === 'asc' ? -1 : 1;
  } else {
    return aA > bA ? (sort === 'asc' ? 1 : -1) : sort === 'asc' ? -1 : +1;
  }
};

const sortOperation = (a: any, b: any, sort: 'asc' | 'desc') => {
  return sort === 'asc' ? b - a : a - b;
};
interface ISortByType {
  type: string;
  b: any;
  a: any;
  sort: 'asc' | 'desc';
}

export const sortByType = ({ type, b, a, sort }: ISortByType): number => {
  let res = 0;
  switch (type) {
    case 'id':
      res = sortAlphaNum(b, a, sort);
      break;
    case 'date':
      res = sortOperation(+new Date(a), +new Date(b), sort);
      break;
    case 'percent':
      res = sortOperation(+a, +b, sort);
      break;
    case 'currency':
      res = sortOperation(+a, +b, sort);
      break;
    case 'integer':
      res = sortOperation(+a, +b, sort);
      break;
    default:
      res = sort === 'asc' ? `${b}`.localeCompare(`${a}`) : `${a}`.localeCompare(`${b}`);
      break;
  }
  return res;
};

interface ISortById {
  id: string;
  labels: any;
  b: any;
  a: any;
  sort: 'asc' | 'desc';
}

const checkLabelExists = (labels: any, data: string) => {
  return labels && data && labels[data];
};

export const sortById = ({ id, a, b, labels, sort }: ISortById): number => {
  let labelA = '',
    labelB = '';
  switch (id) {
    case 'warehouse':
      if (checkLabelExists(labels, a) && checkLabelExists(labels, b)) {
        labelA = labels[a];
        labelB = labels[b];
      }
      break;
    case 'fulfillmentStatus':
      break;
    case 'paymentStatus':
      break;
    default:
      break;
  }
  if (!labelA || !labelB) {
    return 0;
  }
  return sort === 'asc' ? `${b}`.localeCompare(`${a}`) : `${a}`.localeCompare(`${b}`);
};

export const orderDetailsLabelKey = (id: string, labels: any) => {
  switch (id) {
    case 'fulfillmentStatus':
      return 'fulfillmentStatusSetting';
    case 'paymentStatus':
      return 'paymentStatusSetting';
    case 'warehouse':
      return 'paymentStatusSetting';
  }
};

interface ISearchFilter {
  searchTerm: string;
  itemsList: Array<any>;
  settings: any;
}

// checking the index of query
const indexOf = (itemDesc: string, q: string) => {
  let result = false;
  const parts = q.split(' ');
  const count = parts.length;
  for (let i = 0; i < count; i++) {
    if (itemDesc.indexOf(parts[i]) > -1) {
      result = true;
      break;
    }
  }
  return result;
};

export const searchFilter = ({ searchTerm, itemsList, settings }: ISearchFilter) => {
  let pieces: Array<any> = [];
  // dividing search term
  if (searchTerm && searchTerm.length) {
    // @Todo - Make / dynamic to come from config
    const toBePieces = searchTerm.replace(/\//gi, '+').split('+');
    for (let i = 0; i < toBePieces.length; i++) {
      const pieceTerm = toBePieces[i].trim().toLowerCase();
      if (pieceTerm) {
        pieces.push(pieceTerm);
      }
    }
  }

  const results: Array<any> = [];

  itemsList.forEach((item: any) => {
    // is a match for the search term
    let matchSearchTerm = false;
    if (pieces.length) {
      // creating description to search in
      let description = JSON.stringify(Object.values(item)).toLowerCase();
      if (
        item.warehouse &&
        settings['warehouseLabels'] &&
        settings['warehouseLabels'][item.warehouse]
      ) {
        description = settings['warehouseLabels'][item.warehouse].toLowerCase() + description;
      }
      // if(date)
      // counting the degree of match
      let countApprovals = 0;
      pieces.forEach((term: string) => {
        if (indexOf(description, term)) {
          countApprovals += 1;
        }
      });
      if (pieces.length === countApprovals) {
        matchSearchTerm = true;
      }
    }
    if (!searchTerm || !searchTerm.length) {
      matchSearchTerm = true;
    }
    // If item survives filtering, add it to final list
    if (matchSearchTerm) {
      results.push({ ...item });
    }
  });

  return results;
};

// field validation
export const fieldValidation = (value: string, type: string, I18n?: any) => {
  let errorValRes = '';
  switch (type) {
    case 'alphanumeric':
      if (!value.match(/^([0-9]|[a-z])+([0-9a-z]+)$/i)) {
        errorValRes =
          I18n?.pleaseEnterAlphaNumericValueOfBuyer?.i18n_value ||
          'Please enter alpha numeric value of buyer';
      }
      break;
    case 'atleast-one-alphabet':
      if (!value.match(/^[a-z]+[a-z0-9]*/i)) {
        errorValRes =
          I18n?.pleaseStartWithAtleastOneAlphabet?.i18n_value ||
          'Please start with atleast one alphabet';
      }
      break;
    case 'number':
      if (!value.match(/^[0-9]+$/i)) {
        errorValRes = I18n?.invalidNumber?.i18n_value || 'Invalid number';
      }
      break;
    case 'integer':
      if (!value.match(/^[0-9]+$/i)) {
        errorValRes = I18n?.invalidNumber?.i18n_value || 'Invalid number';
      }
      break;
    case 'currency':
      if (!value.match(/^[0-9]+$/i)) {
        errorValRes = I18n?.invalidCurrencyValue?.i18n_value || 'Invalid currency value';
      }
      break;
    case 'internationalized-zip':
      // eslint-disable-next-line no-useless-escape
      if (
        value.match(/(^[-]+[\S \n]*[-]*$)|(^[-]*[\S \n]*[-]+$)/gi) ||
        // eslint-disable-next-line no-useless-escape
        !value.match(/^[a-z0-9\-]+[\s]?[a-z0-9\-]*$/i)
      ) {
        errorValRes = I18n?.invalidZip?.i18n_value || 'Invalid zip';
      }
      break;
    case 'required':
      if (!value) {
        errorValRes = I18n?.requiredField?.i18n_value || 'Required field';
      }
      break;
    case 'email':
      // eslint-disable-next-line no-useless-escape
      if (
        !/^(?:(?!\.{2,}).)*$/.test(value) ||
        !/^([\p{L}\p{M}\p{N}+\-#$!%&_])([\p{L}\p{M}\p{N}.+\-#$!%&_]){0,99}@([\p{L}\p{M}\p{N}.+\-#$!%&_]{1,253}\.){1,8}[a-zA-Z]{2,63}$/u.test(
          value
        )
      ) {
        errorValRes = I18n?.invalidEmail?.i18n_value || 'invalid email';
      }
      break;
    case 'tel': {
      // eslint-disable-next-line no-useless-escape
      if (!value.match(/^[+]?[0-9/() .\-\/]{10,15}/g) || value.match(/[a-z]/g)) {
        // telephone validation
        errorValRes = I18n?.invalidTelephoneNumber?.i18n_value || 'invalid telephone number';
      }
      break;
    }
    // atleast on alphabet and other can be numbers => country/state/city
    // numbers with special characters => zip
    case 'text':
      break;
  }
  return errorValRes;
};

export const specialSymbols: any = {
  [Infinity]: '∞',
};

export const checkNullOrUndefined = (val: any) => {
  return val === null || val === undefined;
};

export type DataFormattingType =
  | 'id'
  | 'percent'
  | 'percent-with-2-decimals'
  | 'currency'
  | 'currency-with-no-decimal'
  | 'integer'
  | 'string'
  | 'number';

export const dataFormatting = (
  type: DataFormattingType,
  value: any,
  isZeroShownForCurrency?: boolean
) => {
  switch (type) {
    case 'id':
      return value || value === 0 ? `${value}` : '--';
    case 'percent':
      return percentFormatting(value, 1);
    case 'percent-with-2-decimals':
      return percentFormatting(value, 2);
    case 'currency':
      const currencyPrecision = localStorage.getItem('PxCurrencyPrecision');
      const decimalPointLimit: number = currencyPrecision ? +currencyPrecision : 2;
      return currencyFormatter(value, decimalPointLimit, isZeroShownForCurrency);
    case 'currency-with-no-decimal':
      return currencyFormatter(value, 0, isZeroShownForCurrency);
    case 'integer':
      return integerFormatting(value);
    case 'number':
      return value
        ? value.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2 })
        : '--';
    default:
      return value ? specialSymbols[value] || value : '--';
  }
};

const percentFormatting = (value: any, decimalDig: number) => {
  if (value === Infinity) return specialSymbols[value];
  if (typeof value === 'string') return `${value}%`;
  return +value ? `${(+value).toFixed(decimalDig)}%` : value && value !== 'NaN' ? value : '--';
};

const integerFormatting = (value: any) => {
  return `${
    value ? value.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 0 }) : '--'
  }`;
};

const currencyFormatter = (value: any, minimumFractionDigits: number, isZeroShown?: boolean) => {
  if ((!isZeroShown || (isZeroShown && value !== 0)) && !value) return '--';
  if (value === Infinity) return specialSymbols[value];
  const localeCurrency = localStorage.getItem('PxCurrencySymbol');
  let currencySymbol = '$';
  if (localeCurrency) {
    currencySymbol = localeCurrency;
  }

  if (typeof value === 'string') {
    return `${currencySymbol}${value}`;
  }
  const val = +(+value).toFixed(minimumFractionDigits);
  return val >= 0
    ? `${currencySymbol}${val.toLocaleString('en', {
        useGrouping: true,
        minimumFractionDigits,
      })}`
    : `-${currencySymbol}${Math.abs(val).toLocaleString('en', {
        useGrouping: true,
        minimumFractionDigits,
      })}`;
};

export const formatToFloatLocaleStringCurrency = (num: number) => {
  if (!num) return '--';
  const localeCurrency = localStorage.getItem('PxCurrencySymbol');
  let currencySymbol = '$';
  if (localeCurrency) currencySymbol = localeCurrency;
  const currencyPrecision = localStorage.getItem('PxCurrencyPrecision');
  const decimalPointLimit: number = currencyPrecision ? +currencyPrecision : 2;
  return `${currencySymbol}${num.toLocaleString(undefined, {
    minimumFractionDigits: decimalPointLimit,
    maximumFractionDigits: decimalPointLimit,
  })}`;
};

export const restrictCurrencyDecimals = (value: string) => {
  const decimalPoints = value.toString().split('.')[1]?.length ?? 0;
  const currencyPrecision = localStorage.getItem('PxCurrencyPrecision');
  if (currencyPrecision && decimalPoints > parseInt(currencyPrecision)) return true;
  if (currencyPrecision === '0' && value.includes('.')) return true;
  return false;
};

export const validateShippingAddInfo: validatorType = async ({
  validationState,
  oldChoices,
  newValue,
  changedState,
}) => {
  if (validationState) {
    const shippingAddressId = oldChoices[changedState];
    if (shippingAddressId === newValue) {
      // no updates are done
      return {
        error: true,
      };
    }
    // if shipping method id is available in the new shipping method code
    return {
      success: true,
    };
  }
  return {
    error: true,
  };
};

export const generateBillingShippingUIData = (
  I18n: any,
  additionalServices: Array<any>
): Array<IShippingUI> => {
  const uiData: Array<IShippingUI> = [
    {
      title: I18n?.shipAddress?.i18n_value || 'Shipping Address',
      optionId: 'shippingAddress',
      payloadKey: 'shippingAddresses',
      dependentPayloadKey: 'shippingMethods',
      selectOptionsHeadingLabel:
        I18n?.pleaseSelectShippingAddress?.i18n_value || 'Please Select Shipping Address',
    },
    {
      title: I18n?.shipMethod?.i18n_value || 'Shipping Method',
      optionId: 'shippingMethod',
      payloadKey: 'shippingMethods',
      selectOptionsHeadingLabel:
        I18n?.pleaseSelectShippingMethod?.i18n_value || 'Please Select Shipping Method',
    },
    {
      title: I18n?.billingAddress?.i18n_value || 'Billing Address',
      optionId: 'billingAddress',
      payloadKey: 'billingAddresses',
      dependentPayloadKey: 'paymentMethods',
      selectOptionsHeadingLabel:
        I18n?.pleaseSelectBillingAddress?.i18n_value || 'Please Select Billing Address',
    },
    {
      title: I18n?.payMethod?.i18n_value || 'Payment Method',
      optionId: 'paymentMethod',
      payloadKey: 'paymentMethods',
      selectOptionsHeadingLabel:
        I18n?.pleaseSelectPaymentMethod?.i18n_value || 'Please Select Payment Method',
    },
  ];

  if (additionalServices && additionalServices?.length > 0)
    uiData.push({
      title: I18n?.additionalService?.i18n_value_plural || 'Additional Services',
      optionId: 'additionalServices',
      payloadKey: 'additionalServices',
      selectOptionsHeadingLabel:
        I18n?.chooseAdditionalServices?.i18n_value || 'Choose Additional Services',
    });

  return uiData;
};

// order details rules
export const validateNewValueEmpty: validatorType = async ({ newValue }) => {
  if (!newValue) {
    return {
      error: true,
    };
  }

  return {
    success: true,
  };
};

export const createShippingOptions = (defaultChoices: IBillingShippingDefaults, options: any) => {
  const updateSelectedOptions = {
    shippingAddress: defaultChoices.shippingAddress?.id,
    shippingMethod: defaultChoices.shippingMethod?.shippingMethodCode,
    billingAddress: defaultChoices.billingAddress?.id,
    paymentMethod: defaultChoices.paymentMethod?.paymentMethodCode,
    additionalServices: defaultChoices.additionalServiceIds,
    billMyAccountInfo: {
      accountNumber: defaultChoices?.billMyAccountDefault?.accountNumber || '',
      carrier: defaultChoices?.billMyAccountDefault?.carrier || '',
      serviceLevel: defaultChoices?.billMyAccountDefault?.carrierServiceLevel || '',
    },
    requestAQuoteInfo: {
      amount: 0,
      serviceLevel: '',
    },
  };

  // default selection of bill my account
  if (
    defaultChoices.billMyAccountDefault &&
    Object.keys(defaultChoices.billMyAccountDefault).length
  ) {
    const isCarrierOptionsAvailable = !!options?.billMyAccountShippingMethod?.carrierOptions
      ?.length;
    const isServiceLevelsAvailable =
      isCarrierOptionsAvailable &&
      options.billMyAccountShippingMethod.carrierServiceLevelOptions &&
      options.billMyAccountShippingMethod.carrierServiceLevelOptions[
        options.billMyAccountShippingMethod.carrierOptions[0]
      ]?.length;

    if (!updateSelectedOptions.billMyAccountInfo.carrier && isCarrierOptionsAvailable) {
      updateSelectedOptions.billMyAccountInfo.carrier =
        options.billMyAccountShippingMethod.carrierOptions[0];
    }

    if (!updateSelectedOptions.billMyAccountInfo.serviceLevel && isServiceLevelsAvailable) {
      updateSelectedOptions.billMyAccountInfo.serviceLevel =
        options.billMyAccountShippingMethod.carrierServiceLevelOptions[
          options.billMyAccountShippingMethod?.carrierOptions[0]
        ][0];
    }
  }
  return updateSelectedOptions;
};

export const isBuyerSite = true;

export const generateShippingValidationMsg = (I18n: any, id: Array<string>) => {
  const label: Array<string> = [];
  id.forEach((val) => {
    switch (val) {
      case 'shippingMethod':
        label.push(I18n?.shipMethod?.i18n_value || 'Shipping Method');
        break;
      case 'paymentMethod':
        label.push(I18n?.payMethod?.i18n_value || 'Payment Method');
        break;
      case 'shippingAddress':
        label.push(I18n?.shipAddress?.i18n_value || 'Shipping Address');
        break;
      case 'billingAddress':
        label.push(I18n?.billingAddress?.i18n_value || 'Billing Address');
        break;
      case 'additionalServices':
        label.push(I18n?.additionalService?.i18n_value || 'Additional Services');
        break;
    }
  });
  return label.join(', ');
};

export const getAnalyticsData = (
  lineItems: Array<any>,
  phonexOrderNumber: string,
  cartTotal: number,
  warehouseCode: string
) => {
  return {
    lineItems,
    phonexOrderNumber,
    cartTotal,
    warehouseCode,
  };
};

export const shippingValidationPairs: IShippingValidationPair = {
  shippingAddress: 'shippingMethod',
  billingAddress: 'paymentMethod',
};

export const getTimeZoneAbbr = (date: Date | number) => {
  // convert date to string, and grab last group with () brackets, which has timezone info.
  const groups = Array.from(date.toString().matchAll(/\((.*?)\)/g));
  const timeZoneInfo = groups[groups.length - 1][1];
  // construct timezone abbrevation
  const timeZoneInfoShort = timeZoneInfo
    .split(' ')
    .map((part) => part.charAt(0).toUpperCase())
    .join('');
  return timeZoneInfoShort;
};

export const formatDateWithTimeZone = (date: string) => {
  const dateObj = new Date(date);
  const shortTimeZone = getTimeZoneAbbr(dateObj);
  const formattedDate = format(dateObj, 'MMM dd, yyy, hh:mm aaa');
  return `${formattedDate} ${shortTimeZone}`;
};

export const logOutWithoutMessageAndRedirectToLogin = async () => {
  try {
    await Auth.signOut();
    localStorage.clear();
    window.indexedDB.deleteDatabase('d2');
    document.location.href = '/';
  } catch (error: any) {
    console.error('error signing out: ', error);
  }
};

export const showAuctions = (authorities: any) =>
  authorities.CAN_VIEW_AUCTION || authorities.CAN_BID_IN_AUCTION;

// Sanitize String
// eslint-disable-next-line no-useless-escape
const illegalRe = /[\/\?<>\\:\*\|":®©℗]/g;
// eslint-disable-next-line no-control-regex
const controlRe = /[\x00-\x1f\x80-\x9f]/g;
export function sanitizeString(input: string, replacement: string = '_') {
  return input.replace(illegalRe, replacement).replace(controlRe, replacement);
}
