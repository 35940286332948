import React, { forwardRef } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../ReviewStockImport';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { I18nContext } from '../../../I18n';
import { dataFormatting, getAttrLabel } from '../../../_lib/lib';

export default forwardRef((props: any, ref: any) => {
  const I18n = React.useContext(I18nContext);

  const {
    data,
    stockListSettings,
    warehousesObj,
    totalValidRequestsLength,
    curTenantSettings,
    warehouseGroupAcceptsOffers,
  } = props;
  const classes = useStyles();
  const maxQuantityToDisplay = stockListSettings['maxItemQuantityToBeDisplayed'];

  return (
    <div id="valid-request" className="cell small-12" ref={ref}>
      <Box className="cell small-12 px-review-box px-box" boxShadow={2}>
        <div className="grid-x">
          <div className="cell small-12">
            <CheckCircleIcon
              className={`margin-right-1 vertical-middle px-review-icons ${classes.success}`}
            />
            <Typography className="inline-block" variant="h5">
              {I18n._format(I18n?.xValidReqs?.i18n_value || 'There are ${1} new requests', [
                totalValidRequestsLength,
              ])}
            </Typography>
          </div>
        </div>
        {Object.keys(data).map((groupKey) => (
          <div key={groupKey} className="margin-top-3">
            <Typography className="margin-bottom-1" variant="h6">
              {data[groupKey][0].groupName}
            </Typography>
            <div className="grid-x">
              <div className="cell small-3 text-muted capitalize">
                {I18n?.item?.i18n_value || 'Item'}
              </div>
              <div className="cell small-2 text-muted grid-x align-middle align-center text-center uppercase">
                {I18n?.sku?.i18n_value || getAttrLabel(curTenantSettings, 'item_number', 'SKU')}
              </div>
              <div className="cell small-1 text-muted grid-x align-middle align-center text-center capitalize">
                {I18n?.warehouse?.i18n_value ||
                  getAttrLabel(curTenantSettings, 'warehouse', 'Warehouse')}
              </div>
              <div className="cell small-1 text-muted grid-x align-middle align-center text-center capitalize">
                {I18n?.availableQty?.i18n_value || 'Available Quantity'}
                {/*br*/}
              </div>
              <div className="cell small-1 text-muted grid-x align-middle align-center text-center capitalize">
                {I18n?.listPrice?.i18n_value || 'List Price'}
                {/*br*/}
              </div>
              {warehouseGroupAcceptsOffers ? (
                <>
                  <div className="cell small-1 text-muted grid-x align-middle align-center text-center capitalize">
                    {I18n?.offerQty?.i18n_value || 'Offer Quantity'}
                  </div>
                  <div className="cell small-1 text-muted grid-x align-middle align-center text-center capitalize">
                    {I18n?.offerPrice?.i18n_value || 'Offer Price'}
                  </div>
                </>
              ) : (
                <div className="cell small-1 text-muted grid-x align-middle align-center text-center capitalize">
                  {I18n?.newQuantity?.i18n_value || 'New Quantity'}
                </div>
              )}
            </div>
            {data[groupKey].map((item: any) => {
              let warehouse = item.warehouse;
              if (item.pxnOffer) {
                warehouse = warehousesObj[item.pxnsParticipantId].warehouses.find(
                  (warehouseObj: any) => +warehouseObj.warehouseId === +warehouse
                )?.warehouseLabel;
              } else {
                warehouse = stockListSettings['warehouseLabels'][item.warehouse];
              }
              return (
                <div key={item.itemNumberWarehouse} className="grid-x margin-top-2">
                  <div className="cell small-3 grid-x align-middle align-left">
                    <label className="wrap-word-any">{item.groupItemName}</label>
                  </div>
                  <div className="cell small-2 grid-x align-middle align-center text-center">
                    <label className="wrap-word-any">{item.itemNumber}</label>
                  </div>
                  <div className="cell small-1 grid-x align-middle align-center text-center">
                    <label className="wrap-word-space">{warehouse}</label>
                  </div>
                  <div className="cell small-1 grid-x align-middle align-center text-center">
                    <label className="wrap-word-any">
                      {maxQuantityToDisplay
                        ? item.availableQuantity > maxQuantityToDisplay
                          ? `${maxQuantityToDisplay}+`
                          : item.availableQuantity
                        : item.availableQuantity}
                    </label>
                  </div>
                  <div className="cell small-1 grid-x align-middle align-center text-center">
                    <label className="wrap-word-any">
                      {dataFormatting('currency-with-no-decimal', item.listPrice)}
                    </label>
                  </div>
                  <div className="cell small-1 grid-x align-middle align-center text-center">
                    <label className="wrap-word-any">{item.offerQuantity}</label>
                  </div>
                  {warehouseGroupAcceptsOffers && (
                    <div className="cell small-1 grid-x align-middle align-center text-center">
                      <label className="wrap-word-any">
                        {dataFormatting('currency-with-no-decimal', item.offerPrice)}
                      </label>
                    </div>
                  )}
                  <Typography
                    color="error"
                    className="cell auto grid-x align-middle align-center text-center"
                  >
                    {item.comments}
                  </Typography>
                </div>
              );
            })}
          </div>
        ))}
      </Box>
    </div>
  );
});
