import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './Logo.scss';

function Logo(props: any) {
  useEffect(() => {
    if (!props.configState?.topMenuLogoImageStyles) return;
    const logo = document.getElementById('pxTopMenuLogoImage');
    if (!logo) return;
    logo.setAttribute('style', props.configState.topMenuLogoImageStyles);
  }, [props.configState]);
  if (props.configState.logoSmall) {
    return (
      <div className="px-c-logo">
        <img src={`${props.configState.logoSmall}`} alt="Logo" id="pxTopMenuLogoImage" />
        <div className="px-logoReferer"></div>
      </div>
    );
  } else {
    return <div className="px-c-logo">Logo</div>;
  }
}

function mapStateToProps(state: any) {
  return {
    configState: state.configState,
  };
}

export default connect(mapStateToProps)(Logo);
